import { useEffect } from "react";

import { captureException } from "@sentry/react";
import { Button } from "@sonovel/shared-components";
import { FallbackProps } from "react-error-boundary";
import { useHistory } from "react-router-dom";

import { ROUTES } from "@constants";
import MixPanelClient from "@libs/mixpanel/mixpanelClient";

function FullPageErrorFallbackComponent({ resetErrorBoundary, error }: FallbackProps) {
  const history = useHistory();

  const isDynamicImportError = error?.message?.includes("dynamically imported module");

  useEffect(function reloadWhenFailToDynamicImport() {
    // NOTE : https://github.com/remix-run/react-router/discussions/10333#discussioncomment-6835184
    if (isDynamicImportError) {
      window.location.reload();
    }
  }, []);

  useEffect(function trackError() {
    if (isDynamicImportError) return;
    captureException(error);
    MixPanelClient.track("error_fallback", { type: "full_page", error: error?.message });
  }, []);

  const pageRefresh = () => {
    MixPanelClient.track("click_error_refresh_button");
    window.location.reload();
  };

  const goHome = () => {
    MixPanelClient.track("click_error_home_button");
    resetErrorBoundary();
    history.push(ROUTES.MAIN);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-2 py-5" role="alert">
      <p className="font-bold">Something went wrong!</p>
      <div className="gap-x-5 flex mt-6">
        <Button className="w-25" emphasis="primary" label="Reload" onClick={pageRefresh}></Button>
        <Button className="w-25" emphasis="tertiary" label="Go Home" onClick={goHome}></Button>
      </div>
    </div>
  );
}

export default FullPageErrorFallbackComponent;
