import { useCallback, useEffect } from "react";

import { APP_MESSAGES } from "@sonovel/shared-constants";

export const useHandleAppMessages = () => {
  const appMessageHandler = useCallback((event: MessageEvent) => {
    switch (event.data.type) {
      case APP_MESSAGES.UPDATE_ACCESS_TOKEN: {
        const { accessToken } = JSON.parse(event.data);
        window.App?.setAccessToken(accessToken);
        break;
      }
      default:
        break;
    }
  }, []);

  useEffect(
    function registerAppMessageHandler() {
      window.addEventListener("message", appMessageHandler);
      return () => window.removeEventListener("message", appMessageHandler);
    },
    [appMessageHandler]
  );
};
