import classNames from "classnames";

import { ReactComponent as EmailLogo } from "@assets/images/email-logo.svg";
import googleLogo from "@assets/images/google-logo.png";
import { OAuthProvider } from "@libs/OAuth/OAuthClient";

type Props = {
  onClick: () => void;
  method: OAuthProvider | "email";
  type: "signUp" | "signIn";
};

const methodTypeComponentMap = {
  google: {
    signUp: {
      text: "Sign up with Google",
      icon: <img src={googleLogo} />,
    },
    signIn: {
      text: "Continue with Google",
      icon: <img src={googleLogo} />,
    },
  },
  apple: {
    signUp: {
      text: "Sign up with Apple",
      icon: "",
    },
    signIn: {
      text: "Continue with Apple",
      icon: "",
    },
  },
  email: {
    signUp: {
      text: "Sign up with email",
      icon: <EmailLogo />,
    },
    signIn: {
      text: "Continue with email",
      icon: <EmailLogo />,
    },
  },
};

function SignButton({ onClick, method, type }: Props) {
  return (
    <button
      className={classNames(
        "interaction-bg w-75 h-[54px] border  flex items-center justify-center gap-2.5 rounded-lg",
        {
          ["border-[#000] dark:bg-[#000] "]: method === "apple",
          ["border-[#BFBFBF] dark:bg-dark-theme-200 dark:border-none"]: method !== "apple",
        }
      )}
      onClick={onClick}
    >
      <div className="w-4 h-4 text-[23px] flex items-center justify-center">
        {methodTypeComponentMap[method][type].icon}
      </div>
      <p className=" text-[19px] font-[590] leading-6 ">{methodTypeComponentMap[method][type].text}</p>
    </button>
  );
}

export default SignButton;
