import { create } from "zustand";
import { combine } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { ModalStoreState, ShowModalPayload } from "./types";

const initialModalState = {
  show: false,
  modalContent: null,
  modalOption: {
    shouldRenderFullWhenMobile: false,
    canClickBackDrop: true,
  },
};

const initialModalStoreState: ModalStoreState = {
  modal: { ...initialModalState },
  secondModal: { ...initialModalState },
};

const useModalStore = create(
  immer(
    combine(initialModalStoreState, (set) => ({
      actions: {
        showModal: ({ modalContent, modalOption }: ShowModalPayload) =>
          set((state) => {
            state.modal.show = true;
            state.modal.modalContent = modalContent;
            state.modal.modalOption = modalOption ?? state.modal.modalOption;
          }),
        closeModal: () =>
          set((state) => {
            state.modal = initialModalState;
          }),

        showSecondModal: ({ modalContent, modalOption }: ShowModalPayload) =>
          set((state) => {
            state.secondModal.show = true;
            state.secondModal.modalContent = modalContent;
            state.secondModal.modalOption = modalOption ?? state.secondModal.modalOption;
          }),
        closeSecondModal: () =>
          set((state) => {
            state.secondModal = initialModalState;
            // (state.secondModal.show = initialModalState.show),
            //   (state.secondModal.modalContent = initialModalState.modalContent),
            //   (state.secondModal.modalOption = initialModalState.modalOption);
          }),
      },
    }))
  )
);

// Selectors
export const useModalState = () =>
  useModalStore((state) => ({
    show: state.modal.show,
    modalContent: state.modal.modalContent,
    modalOption: state.modal.modalOption,
  }));
export const useSecondModalState = () =>
  useModalStore((state) => ({
    show: state.secondModal.show,
    modalContent: state.secondModal.modalContent,
    modalOption: state.secondModal.modalOption,
  }));

// Actions
export const useModalActions = () =>
  useModalStore((state) => ({
    showModal: state.actions.showModal,
    closeModal: state.actions.closeModal,
  }));

export const useSecondModalActions = () =>
  useModalStore((state) => ({
    showSecondModal: state.actions.showSecondModal,
    closeSecondModal: state.actions.closeSecondModal,
  }));
