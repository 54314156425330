import { useEffect } from "react";

import { captureException } from "@sentry/react";
import { Button } from "@sonovel/shared-components";
import { FallbackProps } from "react-error-boundary";

import MixPanelClient from "@libs/mixpanel/mixpanelClient";

function DefaultErrorFallbackComponent({ resetErrorBoundary, error }: FallbackProps) {
  useEffect(function trackError() {
    MixPanelClient.track("error_fallback", { type: "component", error: error?.message });
    captureException(error);
  }, []);

  const onClickRetry = () => {
    MixPanelClient.track("click_error_reset_button");
    resetErrorBoundary();
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-2 py-5" role="alert">
      <p className=" font-bold">Something went wrong!</p>
      <Button emphasis="tertiary" label="Try again" onClick={onClickRetry}></Button>
    </div>
  );
}

export default DefaultErrorFallbackComponent;
