type ShortcutRowProp = {
  name: string;
  keys: string[];
};

function KeyboardShortcutHelpRow({ name, keys }: ShortcutRowProp) {
  return (
    <div className="flex flex-wrap items-center gap-2">
      <p className="text-body-md">{name}: </p>
      <p className="flex items-center gap-2">
        {keys.map((key) => (
          <mark key={key} className="text-body-sm px-2 py-1 font-bold text-gray-900 bg-gray-200 rounded">
            {key}
          </mark>
        ))}
      </p>
    </div>
  );
}

export default KeyboardShortcutHelpRow;
