import { ComponentProps, ReactNode, Suspense } from "react";

import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import DefaultErrorFallbackComponent from "@components/Error/DefaultErrorFallbackComponent";
import Spinner from "@components/Spinner";

interface AsyncErrorBoundaryProps {
  fallbackComponent: React.ComponentType<FallbackProps>;
  children: ReactNode;
  showSpinner?: boolean;
  LoadingFallbackComponent: ReactNode | null;
  onReset?: () => void;
}

const AsyncErrorBoundary = ({
  fallbackComponent = DefaultErrorFallbackComponent,
  children,
  showSpinner = false,
  onReset,
  LoadingFallbackComponent,
}: AsyncErrorBoundaryProps) => {
  const { reset } = useQueryErrorResetBoundary();

  const onResetErrorBoundary = () => {
    onReset?.();
    reset();
  };

  return (
    <ErrorBoundary onReset={onResetErrorBoundary} FallbackComponent={fallbackComponent}>
      <Suspense fallback={showSpinner ? LoadingFallbackComponent : null}>{children}</Suspense>
    </ErrorBoundary>
  );
};

type MakeAsyncComponentOptions = {
  errorFallbackComponent?: React.ComponentType<FallbackProps>;
  loadingFallbackComponent?: ReactNode;
  showSpinner?: boolean;
  onReset?: () => void;
};

export const withAsyncErrorBoundary = <P extends ComponentProps<any>>(
  TargetComponent: React.FC<P>,
  {
    errorFallbackComponent = DefaultErrorFallbackComponent,
    loadingFallbackComponent,
    showSpinner = false,
    onReset,
  }: MakeAsyncComponentOptions = {}
) => {
  const showLoadingFallback = showSpinner || !!loadingFallbackComponent;
  const LoadingFallbackComponent = showLoadingFallback ? loadingFallbackComponent ?? <Spinner /> : null;

  const Component = (props: P) => {
    return (
      <AsyncErrorBoundary
        fallbackComponent={errorFallbackComponent}
        onReset={onReset}
        showSpinner={showLoadingFallback}
        LoadingFallbackComponent={LoadingFallbackComponent}
      >
        <TargetComponent {...(props as JSX.IntrinsicAttributes & P)} />
      </AsyncErrorBoundary>
    );
  };
  Component.displayName = TargetComponent.displayName ?? TargetComponent.name;

  return Component;
};

export default AsyncErrorBoundary;
