import { isMac } from ".";

export const isMetaKeyPressed = (event: KeyboardEvent) => {
  return isMac() ? event.metaKey : event.ctrlKey;
};

export const isAltKeyPressed = (event: KeyboardEvent) => {
  return event.altKey;
};

export const isShiftKeyPressed = (event: KeyboardEvent) => {
  return event.shiftKey;
};

export const isNumberKeyPressed = (e: KeyboardEvent) => {
  switch (e.key) {
    case "0":
    case "1":
    case "2":
    case "3":
    case "4":
    case "5":
    case "6":
    case "7":
    case "8":
    case "9":
      return true;
    default:
      return false;
  }
};

export const cmdOrCtrlKey = isMac() ? "⌘" : "Ctrl";
export const optOrAltKey = isMac() ? "Option" : "Alt";
