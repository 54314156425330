import ReactGA from "react-ga4";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import { ROUTES, TIME } from "@constants";
import { handleUpdateAfterSignIn } from "@libs/api/auth";
import { GqlClient } from "@libs/graphql/client";
import { useSignInReqMutation, WrongSignUpProviderError } from "@libs/graphql/generated-query";
import MixPanelClient from "@libs/mixpanel/mixpanelClient";
import mixpanelUtils from "@libs/mixpanel/mixpanelUtils";
import { OAuthProvider } from "@libs/OAuth/OAuthClient";
import { GraphqlResponse, isDocumentTypeNameSameAs, isGraphQLResponse, selectDto } from "@utils/graphql";

export const useSignInWithCode = () => {
  const history = useHistory();
  const { mutateAsync } = useSignInReqMutation(GqlClient);

  const signInWithCode = async (code: string, provider: OAuthProvider) => {
    try {
      const { signInOrSignUpByCode } = await mutateAsync({
        code,
        provider,
      });

      const { accessToken, isNewUser, user } = selectDto(signInOrSignUpByCode, "RefreshResponse");

      handleUpdateAfterSignIn({ accessToken: accessToken });

      if (isNewUser) {
        MixPanelClient.track("sign_up_complete", { sign_up_method: "social", social_source: user?.signUpMethod });
        ReactGA.event("sign_up_complete", { sign_up_method: "social", social_source: user?.signUpMethod });

        // NOTE : preference 변경 추적을 위해 기본값 event 전송
        MixPanelClient.track("change_preference", mixpanelUtils.defaultPreferenceToPayload());
      } else {
        MixPanelClient.track("sign_in", { sign_in_method: user?.signUpMethod });
      }

      // TODO : sign-in 전에 페이지 정보가 있다면 해당 페이지로 이동
      history.replace(ROUTES.MAIN);
      return;
    } catch (e: unknown) {
      // NOTE: 로그인을 시도한 provider가 다를 경우, 해당 provider로 로그인하라는 메시지를 띄워준다.
      if (
        isGraphQLResponse(e) &&
        isDocumentTypeNameSameAs<GraphqlResponse, "WrongSignUpProviderError">(e, "WrongSignUpProviderError")
      ) {
        const error = e as WrongSignUpProviderError; // Explicitly cast e to the correct type
        toast.error(`Account signed up with ${error.signUpMethod}.  Please sign in with ${error.signUpMethod}.`, {
          duration: 5 * TIME.SECOND,
        });
        history.replace(ROUTES.SIGN_IN);

        return;
      }

      // TODO : 에러 로그
      history.replace(ROUTES.SIGN_IN);
      toast.error("Oops.. Fail to sign in. Please try again.");
    }
  };

  return { signInWithCode };
};
