import ReactGA from "react-ga4";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import MixPanelClient from "@libs/mixpanel/mixpanelClient";

import { TotalWordCountStoreState } from "./types";

const initialState: TotalWordCountStoreState = {
  totalCharacterCount: 0,
  totalWordCount: 0,
  lastRecordedCharacterCount: 0,
  lastRecordedWordCount: 0,
};

const useTotalWordCountStore = create(
  immer(
    combine(initialState, (set) => ({
      actions: {
        addCharacterCount: () =>
          set((state) => {
            state.totalCharacterCount += 1;
          }),
        addWordCount: () =>
          set((state) => {
            state.totalWordCount += 1;
          }),

        setLastRecordedCharacterCount: (characterCount: number) => {
          set((state) => {
            state.lastRecordedCharacterCount = characterCount;
          });
        },
        setLastRecordedWordCount: (wordCount: number) => {
          set((state) => {
            state.lastRecordedWordCount = wordCount;
          });
        },

        resetTotalWordCount: () => {
          set((state) => {
            state.totalCharacterCount = 0;
            state.lastRecordedCharacterCount = 0;
          });
        },
      },
    }))
  )
);

useTotalWordCountStore.subscribe(function trackCharacterCount(state) {
  const triggerCharacterCount = 300;
  const { totalCharacterCount, lastRecordedCharacterCount } = state;
  const roundDownWordCount = Math.floor(totalCharacterCount / triggerCharacterCount) * triggerCharacterCount;
  if (roundDownWordCount > lastRecordedCharacterCount) {
    MixPanelClient.track("write_chars", { word_count: roundDownWordCount });
    ReactGA.event("write_chars", { word_count: roundDownWordCount });

    useTotalWordCountStore.getState().actions.setLastRecordedCharacterCount(roundDownWordCount);
  }
});

useTotalWordCountStore.subscribe(function trackWordCount(state) {
  const triggerWordCount = 50;
  const { totalWordCount, lastRecordedWordCount } = state;
  const roundDownWordCount = Math.floor(totalWordCount / triggerWordCount) * triggerWordCount;
  if (roundDownWordCount > lastRecordedWordCount) {
    MixPanelClient.track("write_words", { word_count: roundDownWordCount });
    ReactGA.event("write_words", { word_count: roundDownWordCount });

    useTotalWordCountStore.getState().actions.setLastRecordedWordCount(roundDownWordCount);
  }
});

export const useTotalWordCountStoreActions = () => useTotalWordCountStore((state) => state.actions);
