import { forwardRef, Ref } from "react";

import classNames from "classnames";

import "./Radio.css";
import { RadioProps } from "./Radio.types";

export const Radio = forwardRef(function RadioImpl(
  { size = "md", title, titleClassName, description, disabled = false, className, ...restProps }: RadioProps,
  forwardedRef: Ref<HTMLInputElement>
) {
  return (
    <div className={classNames("flex flex-col items-start", { [`radio-${size}`]: true }, className)}>
      <label className="radio-container py-[2px] inline-flex items-center gap-3 shrink-0">
        <input type="radio" disabled={disabled} className="peer sr-only" ref={forwardedRef} {...restProps} />

        <div className="radio-icon peer-checked:text-color-600 peer-checked:border-success-600">
          <div
            className={classNames("invisible bg-success-600 rounded-full", {
              ["w-[6.4px] h-[6.4px]"]: size === "sm",
              ["w-2 h-2"]: size === "md",
              ["w-[10px] h-[10px]"]: size === "lg",
            })}
          />
        </div>
        <p className={classNames("radio-title", titleClassName)}>{title}</p>
      </label>
      <div className="inline-flex items-center gap-3">
        <div className="radio-empty flex invisible" />
        {description && <p className="text-body-sm text-gray-700">{description}</p>}
      </div>
    </div>
  );
});
