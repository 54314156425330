export enum OnboardingSurveyType {
  FIRST = "What best describes you?",
  SECOND = "What kind of stories do you write or plan to write?",
  THIRD = "Have you completed writing a story?",
  FOURTH = "What kind of software did you use for writing before?",
}

export enum OnboardingSurveyFirstTypeOptions {
  FIRST = "I mostly write for fun.",
  SECOND = "I write to pursue a career as a writer.",
  THIRD = "I am a professional writer.",
  NONE = "None of the above",
}

export enum OnboardingSurveyFirstTypeValues {
  FIRST = "FUN",
  SECOND = "CAREER",
  THIRD = "PROFESSION",
  NONE = "NONE",
}

export enum OnboardingSurveySecondTypeOptions {
  FIRST = "Fiction",
  SECOND = "Non-fiction",
  THIRD = "Fan-fiction",
  FOURTH = "Screenwriting",
  NONE = "None of the above",
}

export enum OnboardingSurveySecondTypeValues {
  FIRST = "FICTION",
  SECOND = "NON_FICTION",
  THIRD = "FAN_FICTION",
  FOURTH = "SCREEN_WRITING",
  NONE = "NONE",
}

export enum OnboardingSurveyThirdTypeOptions {
  FIRST = "Yes, I have completed a story.",
  SECOND = "No, I haven’t yet.",
}

export enum OnboardingSurveyThirdTypeValues {
  FIRST = "YES",
  SECOND = "NO",
}

export enum OnboardingSurveyFourthTypeOptions {
  FIRST = "Microsoft Word",
  SECOND = "Google Docs",
  THIRD = "Scrivener",
  NONE = "None of the above",
}

export enum OnboardingSurveyFourthTypeValues {
  FIRST = "WORD",
  SECOND = "DOCS",
  THIRD = "SCRIVENER",
  NONE = "NONE",
}
