import { create } from "zustand";
import { combine } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { ViewStoreState } from "./types";

const initialViewState: ViewStoreState = {
  windowSize: "desktop",
  screenSize: "xs",
};

const useViewStore = create(
  immer(
    combine(initialViewState, (set) => ({
      actions: {
        setWindowSize: (windowSize: "mobile" | "desktop") =>
          set((state) => {
            state.windowSize = windowSize;
          }),
        setScreenSize: (size: "xs" | "sm" | "md" | "lg" | "xl") =>
          set((state) => {
            state.screenSize = size;
          }),
      },
    }))
  )
);

export const useViewWindowSize = () => useViewStore((state) => state.windowSize);
export const useViewScreenSize = () => useViewStore((state) => state.screenSize);
export const useIsMobileView = () => useViewWindowSize() === "mobile";

export const useViewActions = () => useViewStore((state) => state.actions);
