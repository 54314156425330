import { useEffect } from "react";

import { setScreenHeight } from "@sonovel/shared-utils";

const mobileOrientationQuery = window.matchMedia("(orientation: landscape)");

/**
 * NOTE : 모바일 browser 100vh 가 실제 browser 상의 height가 일치하지 않아 계산
 * tailwind 에서 h-screen의 정의 자체를 다시 했으므로, desktop의 경우에도 계산이 필요
 *  */
function useCalculateScreenHeight() {
  useEffect(() => {
    setScreenHeight();

    // 데스크톱 브라우저에서는 resize 이벤트를 사용합니다.
    window.addEventListener("resize", setScreenHeight);

    //NOTE: 모바일 브라우저에서는 일부 브라우저에서 타이밍 이슈가 있어 orientationchange 이벤트를 사용합니다.
    mobileOrientationQuery.addEventListener("change", () => setTimeout(setScreenHeight, 500));

    return () => {
      window.removeEventListener("resize", setScreenHeight);
    };
  }, []);
}

export default useCalculateScreenHeight;
