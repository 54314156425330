type focusedCallback = (focused?: boolean) => void;
/**
 * NOTE : iframe 과 window focus 싱크를 맞추기 위해, focus 여부는 event listener를 통해 관리하지만
 * 실제 focusedCallback 호출은 requestAnimationFrame을 통해 비동기적으로 호출한다.
 */
class WindowFocusManager {
  inIFrame: boolean;
  inWindow: boolean;
  focusedCallbacks: Array<focusedCallback>;
  constructor() {
    this.focusedCallbacks = [];
    this.inIFrame = false;
    // NOTE : focus manage callback으로 주입되므로 bind를 해줘야함
    this.bindMethods();
  }

  onWindowFocus(newCallback: focusedCallback) {
    this.focusedCallbacks.push(newCallback);
    this.addWindowEventListener();

    return () => {
      this.focusedCallbacks = this.focusedCallbacks.filter((registeredCallback) => registeredCallback !== newCallback);
    };
  }

  focusOnIFrame() {
    this.inIFrame = true;
    this.checkWindowFocusFromIframeFocusChange();
  }

  blurOnIFrame() {
    this.inIFrame = false;
    this.checkWindowFocusFromIframeFocusChange();
  }

  private bindMethods() {
    this.onWindowFocus = this.onWindowFocus.bind(this);
    this.handleWindowFocus = this.handleWindowFocus.bind(this);
    this.handleWindowBlur = this.handleWindowBlur.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  private addWindowEventListener() {
    window.addEventListener("focus", this.handleWindowFocus);
    window.addEventListener("blur", this.handleWindowBlur);
    window.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  private handleWindowFocus() {
    this.inWindow = true;
    requestAnimationFrame(() => {
      if (this.inIFrame) return;
      this.notifyFocusChange(true);
    });
  }

  private handleWindowBlur() {
    this.inWindow = false;
    requestAnimationFrame(() => {
      if (this.inIFrame) return;
      this.notifyFocusChange(false);
    });
  }

  private handleVisibilityChange() {
    const isVisible = document.visibilityState === "visible";
    if (!isVisible) {
      this.inIFrame && this.blurOnIFrame();
      this.inWindow = false;
    }
    this.notifyFocusChange(isVisible);
  }

  private checkWindowFocusFromIframeFocusChange() {
    requestAnimationFrame(() => {
      const isWindowFocused = this.inIFrame || this.inWindow;
      this.notifyFocusChange(isWindowFocused);
    });
  }

  private notifyFocusChange(focused: boolean) {
    this.focusedCallbacks.forEach((callback) => callback(focused));
  }
}

const windowFocusManager = new WindowFocusManager();

export default windowFocusManager;
