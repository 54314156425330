import { ReactNode, Ref, forwardRef } from "react";

import classNames from "classnames";

type IconContainerProps = {
  className?: string;
  icon: ReactNode;
  disabled?: boolean;
  selected?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

function IconContainer(
  { selected, icon, disabled, onClick, className }: IconContainerProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      type="button"
      className={classNames("flex items-center justify-center rounded shrink-0", className, {
        "interaction-bg text-gray-600": !disabled,
        "bg-black-1": selected,
        "text-gray-300": disabled,
      })}
      onClick={onClick}
    >
      {icon}
    </button>
  );
}

export default forwardRef(IconContainer);
