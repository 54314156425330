import { useEffect, useRef, useState, CSSProperties } from "react";

import { usePopoverActions, usePopoverState } from "@stores/popover";

const MARGIN_BOTTOM = 10;

const CustomPopover = () => {
  const { show, popoverContent, position, transformOrigin } = usePopoverState();
  const { closePopover } = usePopoverActions();
  const [positionCSS, setPositionCSS] = useState<CSSProperties>({ visibility: "hidden" });
  const popover = useRef<HTMLDivElement>(null);
  const closePopoverByContext = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    closePopover();
  };

  useEffect(
    function tunePopoverPosition() {
      if (show && popover.current) {
        const { height: clientHeight, width: clientWidth } = window.visualViewport as VisualViewport;
        const { clientHeight: popoverHeight, clientWidth: popoverWidth } = popover.current;

        let x: number = 0;
        let y: number = 0;

        switch (true) {
          case transformOrigin.horizontal === "right":
            x = position.x - popoverWidth;
            break;
          case transformOrigin.horizontal === "center":
            x = position.x - popoverWidth / 2;
            break;
          default:
          case transformOrigin.horizontal === "left":
            x = position.x;
            break;
        }

        switch (true) {
          case transformOrigin.vertical === "bottom":
            y = position.y - popoverHeight;
            break;
          case transformOrigin.vertical === "center":
            y = position.y - popoverHeight / 2;
            break;
          default:
          case transformOrigin.vertical === "top":
            y = position.y;
            break;
        }

        const styleCSS: CSSProperties = {};
        styleCSS.left = x + popoverWidth > clientWidth ? clientWidth - popoverWidth : x;
        styleCSS.top = y + popoverHeight > clientHeight ? clientHeight - popoverHeight - MARGIN_BOTTOM : y;

        setPositionCSS(styleCSS);
      }
      return () => {
        setPositionCSS({
          visibility: "hidden",
        });
      };
    },
    [show, position, transformOrigin.horizontal, transformOrigin.vertical]
  );

  if (!show) return null;
  return (
    <section
      className="fixed inset-0 z-50 flex items-center justify-center w-screen h-screen"
      onClick={closePopover}
      onContextMenu={closePopoverByContext}
    >
      <div
        className={`absolute z-50 w-fit rounded-lg bg-white-0 dark:bg-dark-theme-200  `}
        style={positionCSS ?? {}}
        onClick={(e) => e.stopPropagation()}
        ref={popover}
      >
        {popoverContent}
      </div>
    </section>
  );
};

export default CustomPopover;
