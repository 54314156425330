import { forwardRef, Ref } from "react";

import classNames from "classnames";

import { MenuItemProps } from "./MenuItem.types";

export const MenuItem = forwardRef(function MenuItem(
  { children, className, selected, disabled, ...props }: MenuItemProps,
  forwardedRef: Ref<HTMLLIElement>
) {
  return (
    <li
      className={classNames(
        "cursor-pointer inline-flex items-center justify-start min-w-25 px-3 py-2 hover:bg-black-2 active:bg-black-3 focus:bg-black-1 select-none text-body-sm font-medium",
        {
          "text-gray-900": !disabled,
          "bg-gray-300 dark:bg-black-1": selected,
          "pointer-events-none text-gray-300": disabled,
        },
        className
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </li>
  );
});
