// NOTE: 컴포넌트의 css를 tailwind가 overwrite 할 수 있기 때문에 global css를 먼저 import 해줘야 한다.
// https://github.com/tailwindlabs/tailwindcss/discussions/7304#discussioncomment-2256226
import "@assets/css/global.css";

import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { analyticsConfig } from "@sonovel/shared-constants";
import { QueryClient, QueryClientProvider, onlineManager } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom";

import { withAsyncErrorBoundary } from "@components/Error/AsyncErrorBoundary";
import FullPageErrorFallbackComponent from "@components/Error/FullPageErrorFallbackComponent";
import { TIME } from "@constants";
import DesktopInitializer from "@hoc/Initializer/DesktopInitializer/DesktopInitializer";
import ThemeController from "@hoc/ThemeController";

import App from "./App";

if (CONFIG.PHASE !== "LOCAL") {
  Sentry.init({
    dsn: "https://dceb6f208f444ff5ef8c5647de0887ac@o4507134501322752.ingest.us.sentry.io/4507134514626560",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay,
    environment: CONFIG.PHASE,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const theme = createTheme({
  typography: {
    fontFamily: [
      "Pretendard",
      "-apple-system",
      "BlinkMacSystemFont",
      "system-ui",
      "Roboto",
      "Helvetica Neue",
      "Segoe UI",
      "Apple SD Gothic Neo",
      "Malgun Gothic",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "sans-serif",
    ].join(","),
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (retryCount) => {
        return onlineManager.isOnline() && retryCount < 1;
      },
      useErrorBoundary: true,
      staleTime: 3 * TIME.MINUTE,
    },
    mutations: {
      retry: false,
      useErrorBoundary: false,
    },
  },
});

const AppWithAsyncErrorBoundary = withAsyncErrorBoundary(App, {
  showSpinner: true,
  errorFallbackComponent: FullPageErrorFallbackComponent,
});

if (CONFIG.PHASE === "PROD") {
  ReactGA.initialize(analyticsConfig.googleAnalyticsId);
}

createRoot(document.getElementById("app") as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <DesktopInitializer>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DndProvider backend={HTML5Backend}>
            <Router>
              <ThemeController>
                <AppWithAsyncErrorBoundary />
              </ThemeController>
            </Router>
          </DndProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </DesktopInitializer>
  </QueryClientProvider>
);
