import { useQueryClient } from "@tanstack/react-query";

import { TIME } from "@constants";
import { GqlClient } from "@libs/graphql/client";
import { getAuthActions } from "@stores/auth";
import { useTotalWordCountStoreActions } from "@stores/totalWordCount";
import { isDocumentTypeNameSameAs } from "@utils/graphql";
import { RefreshReqDocument, useSignOutReqMutation } from "libs/graphql/generated-query";

type HandleUpdateAfterSignInParam = {
  accessToken: string;
};

let timerId: NodeJS.Timeout;

const { setAccessToken, setAuthStatus } = getAuthActions();

export const refresh = async () => {
  await GqlClient.request(RefreshReqDocument).then(({ refresh }) => {
    if (isDocumentTypeNameSameAs(refresh, "RefreshResponse")) {
      setAccessToken(refresh.accessToken);
      setAuthStatus("authorized");
      return;
    }

    setAccessToken(null);
    setAuthStatus("unauthorized");
  });
};

export const silentRefresh = () => {
  timerId = setTimeout(async () => {
    try {
      await refresh();
      silentRefresh();
    } catch (e) {
      // NOTE : 네트워크 이슈 등으로 실패할 경우
      setTimeout(() => silentRefresh(), 30 * TIME.SECOND);
    }
  }, 25 * TIME.MINUTE);
};

export const stopRefresh = () => {
  if (timerId) {
    clearTimeout(timerId);
  }
};

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const { resetTotalWordCount } = useTotalWordCountStoreActions();

  return useSignOutReqMutation(GqlClient, {
    onSuccess: async () => {
      setAccessToken("");
      setAuthStatus("unauthorized");
      resetTotalWordCount();
      stopRefresh();

      queryClient.clear();
    },
  });
};

export const handleUpdateAfterSignIn = ({ accessToken }: HandleUpdateAfterSignInParam) => {
  setAuthStatus("authorized");
  setAccessToken(accessToken);
  silentRefresh();
};
