import { ReactNode, useLayoutEffect } from "react";

import { SCREEN_SIZE, WINDOW_SIZE } from "@constants";
import { useViewActions } from "@stores/view";

const desktopMediaQuery = window.matchMedia(`(min-width: ${WINDOW_SIZE.MOBILE_SCREEN_WIDTH}px)`);

const smallScreenMediaQuery = window.matchMedia(`(min-width: ${SCREEN_SIZE.SM}px)`);
const mediumScreenMediaQuery = window.matchMedia(`(min-width: ${SCREEN_SIZE.MD}px)`);
const largeScreenMediaQuery = window.matchMedia(`(min-width: ${SCREEN_SIZE.LG}px)`);
const extraLargeScreenMediaQuery = window.matchMedia(`(min-width: ${SCREEN_SIZE.XL}px)`);

function ViewSizeSelector({ children }: { children: ReactNode }) {
  const { setWindowSize, setScreenSize } = useViewActions();

  const updateWindowSize = () => {
    switch (true) {
      case desktopMediaQuery.matches:
        setWindowSize("desktop");
        break;
      default:
        setWindowSize("mobile");
        break;
    }
  };

  const updateScreenSize = () => {
    if (extraLargeScreenMediaQuery.matches) {
      setScreenSize("xl");
    } else if (largeScreenMediaQuery.matches) {
      setScreenSize("lg");
    } else if (mediumScreenMediaQuery.matches) {
      setScreenSize("md");
    } else if (smallScreenMediaQuery.matches) {
      setScreenSize("sm");
    } else {
      setScreenSize("xs");
    }
  };

  useLayoutEffect(() => {
    updateWindowSize();
  }, []);

  useLayoutEffect(() => {
    if (desktopMediaQuery.addEventListener) {
      desktopMediaQuery.addEventListener("change", updateWindowSize);
    }
  }, []);

  useLayoutEffect(() => {
    updateScreenSize();
  }, []);

  useLayoutEffect(() => {
    if (smallScreenMediaQuery.addEventListener) {
      smallScreenMediaQuery.addEventListener("change", updateScreenSize);
    }
    if (mediumScreenMediaQuery.addEventListener) {
      mediumScreenMediaQuery.addEventListener("change", updateScreenSize);
    }
    if (largeScreenMediaQuery.addEventListener) {
      largeScreenMediaQuery.addEventListener("change", updateScreenSize);
    }
    if (extraLargeScreenMediaQuery.addEventListener) {
      extraLargeScreenMediaQuery.addEventListener("change", updateScreenSize);
    }
  }, []);

  return <>{children}</>;
}

export default ViewSizeSelector;
