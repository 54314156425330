import { isDesktopApp } from "@sonovel/shared-utils";
import mixpanel from "mixpanel-browser";

import { OnboardingSurveyType } from "@pages/DashBoard/components/Onboarding/consts";
import { OnboardingFormValues } from "@pages/DashBoard/components/Onboarding/OnboardingSurveyModal";

import { UserEvent } from "./mixpanelEvents";

class MixpanelClientFactory {
  private userIdentified = false;
  private isDesktop = false;

  constructor() {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: CONFIG.PHASE !== "PROD",
      api_host: CONFIG.ENDPOINT.MIXPANEL,
      ignore_dnt: true,
    });
    this.isDesktop = isDesktopApp();
  }

  trackPageView(params?: any) {
    mixpanel.track_pageview({ ...params, isDesktop: this.isDesktop });
  }

  track(event: UserEvent, data?: any) {
    mixpanel.track(event, { ...data, isDesktop: this.isDesktop });
  }

  setUserInfo({ userId, email, isDesktop }: { userId: string; email: string; isDesktop: boolean }) {
    if (!this.userIdentified) {
      mixpanel.identify(userId);
      mixpanel.people.set_once({ $email: email });

      if (isDesktop) {
        mixpanel.people.set_once({ $desktop_user: isDesktop });
      }
      this.userIdentified = true;
    }
  }

  resetUser() {
    if (this.userIdentified) {
      mixpanel.reset();
      this.userIdentified = false;
    }
  }

  saveSurveyResponse(data: OnboardingFormValues) {
    const mixpanelPayload: { [key in string]: string } = {};
    Object.entries(data).forEach(([key, value]) => {
      switch (key) {
        case OnboardingSurveyType.FIRST:
          mixpanelPayload["survey_writing_purpose"] = value;
          break;
        case OnboardingSurveyType.SECOND:
          mixpanelPayload["survey_what"] = value;
          break;
        case OnboardingSurveyType.THIRD:
          mixpanelPayload["survey_complete_story"] = value;
          break;
        case OnboardingSurveyType.FOURTH:
          mixpanelPayload["survey_writing_software"] = value;
          break;
      }
    });
    mixpanel.people.set(mixpanelPayload);
  }
}

const MixPanelClient = new MixpanelClientFactory();

export default MixPanelClient;
