import cloneDeepWith from "lodash/cloneDeepWith";

import { PreferenceCategory, PreferenceName, PreferenceResponse } from "@libs/graphql/generated-query";

import TextReplacePreference from "./textReplacePreference";

export type FontCategory = "default" | "serif" | "mono";
export type FontSizeCategory = "60" | "70" | "80" | "90" | "100" | "110" | "120" | "130" | "140" | "150";
export type LineSpacingCategory = "140" | "180" | "200";
export type WordCountTypeValue = "word" | "character" | "characterWithoutSpace";
export type ThemeCategory = "light" | "dark";

export type PreferenceValue = {
  //TODO: margin 크기 설정은 스펙 확정 후
  [PreferenceName.EditorMargin]: string;
  [PreferenceName.Font]: FontCategory;
  [PreferenceName.FontSize]: FontSizeCategory;
  [PreferenceName.LineSpacing]: LineSpacingCategory;
  [PreferenceName.TextIndent]: "on" | "off";
  [PreferenceName.TypewriterMode]: "on" | "off";
  [PreferenceName.SmartQuote]: "on" | "off";
  [PreferenceName.TextReplaceMode]: "on" | "off";
  [PreferenceName.TextReplaceMap]: TextReplacePreference;
  [PreferenceName.WordCountType]: WordCountTypeValue;
  [PreferenceName.Theme]: ThemeCategory;
};

export type PreferenceKeyMap = {
  [key in PreferenceName]: { name: key; value: PreferenceValue[key]; category: PreferenceCategory };
};

export const DEFAULT_PREFERENCE: PreferenceKeyMap = {
  [PreferenceName.EditorMargin]: {
    name: PreferenceName.EditorMargin,
    value: "160",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.Font]: {
    name: PreferenceName.Font,
    value: "default",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.FontSize]: {
    name: PreferenceName.FontSize,
    value: "100",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.LineSpacing]: {
    name: PreferenceName.LineSpacing,
    value: "180",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.TextIndent]: {
    name: PreferenceName.TextIndent,
    value: "off",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.TypewriterMode]: {
    name: PreferenceName.TypewriterMode,
    value: "off",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.SmartQuote]: {
    name: PreferenceName.SmartQuote,
    value: "on",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.TextReplaceMode]: {
    name: PreferenceName.TextReplaceMode,
    value: "on",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.TextReplaceMap]: {
    name: PreferenceName.TextReplaceMap,
    value: new TextReplacePreference(JSON.stringify({ "...": "…", "-->": "→", "<--": "←", "(c)": "©" })),
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.WordCountType]: {
    name: PreferenceName.WordCountType,
    value: "word",
    category: PreferenceCategory.Editor,
  },
  [PreferenceName.Theme]: {
    name: PreferenceName.Theme,
    value: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
    category: PreferenceCategory.Display,
  },
};

class Preferences {
  private preferencesKeyMap: PreferenceKeyMap;

  constructor(input: PreferenceResponse[]) {
    const prefClone = cloneDeepWith(DEFAULT_PREFERENCE);

    const userPreferences = input.reduce((res, pref) => {
      if (res[pref.name]) {
        if (pref.name === PreferenceName.TextReplaceMap) {
          res[pref.name].value = new TextReplacePreference(pref.value);
        } else {
          res[pref.name].value = pref.value;
        }
      }
      return res;
    }, prefClone);

    this.preferencesKeyMap = userPreferences;
  }

  getCategory<K extends PreferenceCategory>(category: K) {
    const preferenceKeys = Object.keys(this.preferencesKeyMap) as PreferenceName[];

    return preferenceKeys.reduce((res, pref: PreferenceName) => {
      if (this.preferencesKeyMap[pref].category === category) res[pref] = this.preferencesKeyMap[pref];
      return res;
    }, {} as any);
  }

  getPreference<K extends PreferenceName>(key: K) {
    return this.preferencesKeyMap[key];
  }

  static makePreference<K extends PreferenceName>({
    key,
    value,
    category,
  }: {
    key: K;
    value: PreferenceValue[K];
    category: PreferenceCategory;
  }): PreferenceResponse {
    return {
      name: key,
      value: typeof value !== "string" ? value.toJSON() : value,
      category,
    };
  }
}

export default Preferences;
