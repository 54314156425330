import { Redirect, Route, RouteProps } from "react-router";

import { ROUTES } from "@constants";
import { useAuthStatus } from "@stores/auth";

type PublicRouteProps = RouteProps<string> & {
  canSignInUserAccess?: boolean;
};

function PublicRoute({ component, path, canSignInUserAccess = true, ...rest }: PublicRouteProps) {
  const authStatus = useAuthStatus();

  if (authStatus === "pending") return null;
  if (canSignInUserAccess) return <Route {...rest} path={path} component={component} />;
  if (authStatus === "unauthorized") return <Route {...rest} path={path} component={component} />;
  return <Redirect to={ROUTES.MAIN} />;
}

export default PublicRoute;
