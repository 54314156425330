import { useEffect } from "react";

import { usePreferenceIsReady, usePreferenceTheme } from "@stores/preference";

type ThemeControllerProps = {
  children: React.ReactNode;
};

function ThemeController({ children }: ThemeControllerProps) {
  const theme = usePreferenceTheme();
  const isPreferenceInitialized = usePreferenceIsReady();

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      return;
    }

    document.documentElement.classList.remove("dark");
  }, [isPreferenceInitialized, theme]);

  return <>{children}</>;
}

export default ThemeController;
