import merge from "lodash/merge";
import { useStore } from "zustand";
import { combine, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createStore } from "zustand/vanilla";

import { PreferenceName } from "@libs/graphql/generated-query";
import Preferences, { DEFAULT_PREFERENCE, PreferenceValue } from "@models/preferences";

import { PreferenceState } from "./types";

const initialPreferenceState: PreferenceState = {
  preferences: {
    [PreferenceName.EditorMargin]: DEFAULT_PREFERENCE[PreferenceName.EditorMargin],
    [PreferenceName.TypewriterMode]: DEFAULT_PREFERENCE[PreferenceName.TypewriterMode],
    [PreferenceName.TextIndent]: DEFAULT_PREFERENCE[PreferenceName.TextIndent],
    [PreferenceName.Font]: DEFAULT_PREFERENCE[PreferenceName.Font],
    [PreferenceName.FontSize]: DEFAULT_PREFERENCE[PreferenceName.FontSize],
    [PreferenceName.LineSpacing]: DEFAULT_PREFERENCE[PreferenceName.LineSpacing],
    [PreferenceName.SmartQuote]: DEFAULT_PREFERENCE[PreferenceName.SmartQuote],
    [PreferenceName.TextReplaceMode]: DEFAULT_PREFERENCE[PreferenceName.TextReplaceMode],
    [PreferenceName.TextReplaceMap]: DEFAULT_PREFERENCE[PreferenceName.TextReplaceMap],
    [PreferenceName.WordCountType]: DEFAULT_PREFERENCE[PreferenceName.WordCountType],
    [PreferenceName.Theme]: DEFAULT_PREFERENCE[PreferenceName.Theme],
  },
  isReady: false,
};
const preferenceStore = createStore(
  persist(
    immer(
      combine(initialPreferenceState, (set) => ({
        actions: {
          setValue: <K extends PreferenceName>(name: K, value: PreferenceValue[K]) =>
            set((state) => {
              state.preferences[name] = { ...state.preferences[name], value };
            }),

          initStore: (preferences: Preferences) =>
            set((state) => {
              state.isReady = true;
              state.preferences[PreferenceName.LineSpacing].value = preferences.getPreference(
                PreferenceName.LineSpacing
              ).value;
              state.preferences[PreferenceName.Font].value = preferences.getPreference(PreferenceName.Font).value;
              state.preferences[PreferenceName.FontSize].value = preferences.getPreference(
                PreferenceName.FontSize
              ).value;
              state.preferences[PreferenceName.TextIndent].value = preferences.getPreference(
                PreferenceName.TextIndent
              ).value;
              state.preferences[PreferenceName.TypewriterMode].value = preferences.getPreference(
                PreferenceName.TypewriterMode
              ).value;
              state.preferences[PreferenceName.EditorMargin].value = preferences.getPreference(
                PreferenceName.EditorMargin
              ).value;
              state.preferences[PreferenceName.SmartQuote].value = preferences.getPreference(
                PreferenceName.SmartQuote
              ).value;
              state.preferences[PreferenceName.TextReplaceMap].value = preferences.getPreference(
                PreferenceName.TextReplaceMap
              ).value;
              state.preferences[PreferenceName.WordCountType].value = preferences.getPreference(
                PreferenceName.WordCountType
              ).value;
              state.preferences[PreferenceName.Theme].value = preferences.getPreference(PreferenceName.Theme).value;
            }),
          cleanUpStore: () =>
            set((state) => {
              state.preferences = initialPreferenceState.preferences;
              state.isReady = initialPreferenceState.isReady;
            }),
        },
      }))
    ),
    {
      name: "sonovel-preference",
      getStorage: () => localStorage,
      // NOTE : dark mode persistence 를 위해 local storage 사용, ThemeController 에서 사용
      partialize: (state) => ({ preferences: { [PreferenceName.Theme]: state.preferences[PreferenceName.Theme] } }),
      merge: (persistedState, currentState) => merge(currentState, persistedState),
    }
  )
);

const usePreferenceStore = () => useStore(preferenceStore);

export const getTypewriterModePreference = () =>
  preferenceStore.getState().preferences[PreferenceName.TypewriterMode].value;
export const getFontSizePreference = () => preferenceStore.getState().preferences[PreferenceName.FontSize].value;
export const getLineSpacingPreference = () => preferenceStore.getState().preferences[PreferenceName.LineSpacing].value;
export const getSmartQuotePreference = () => preferenceStore.getState().preferences[PreferenceName.SmartQuote].value;
export const getTextReplaceMapPreference = () =>
  preferenceStore.getState().preferences[PreferenceName.TextReplaceMap].value;
export const getWordCountTypePreference = () =>
  preferenceStore.getState().preferences[PreferenceName.WordCountType].value;
export const getThemePreference = () => preferenceStore.getState().preferences[PreferenceName.Theme].value;
export const getMarginPreference = () => preferenceStore.getState().preferences[PreferenceName.EditorMargin].value;

export const usePreferenceStoreState = () => usePreferenceStore().preferences;
// Selectors
export const usePreferenceIsReady = () => usePreferenceStore().isReady;
export const usePreferenceEditorMargin = () => usePreferenceStore().preferences[PreferenceName.EditorMargin].value;
export const usePreferenceFont = () => usePreferenceStore().preferences[PreferenceName.Font].value;
export const usePreferenceFontSize = () => usePreferenceStore().preferences[PreferenceName.FontSize].value;
export const usePreferenceTextIndent = () => usePreferenceStore().preferences[PreferenceName.TextIndent].value;
export const usePreferenceLineSpacing = () => usePreferenceStore().preferences[PreferenceName.LineSpacing].value;
export const usePreferenceTypewriterMode = () => usePreferenceStore().preferences[PreferenceName.TypewriterMode].value;
export const usePreferenceSmartQuote = () => usePreferenceStore().preferences[PreferenceName.SmartQuote].value;
export const usePreferenceTextReplaceMode = () =>
  usePreferenceStore().preferences[PreferenceName.TextReplaceMode].value;
export const usePreferenceTextReplaceMap = () => usePreferenceStore().preferences[PreferenceName.TextReplaceMap].value;
export const usePreferenceWordCountType = () => usePreferenceStore().preferences[PreferenceName.WordCountType].value;
export const usePreferenceTheme = () => usePreferenceStore().preferences[PreferenceName.Theme].value;
export const useIsDarkMode = () => usePreferenceStore().preferences[PreferenceName.Theme].value === "dark";

// Actions
export const usePreferenceActions = () => usePreferenceStore().actions;
