import { useState } from "react";

import { Button } from "@sonovel/shared-components";
import { Input } from "@sonovel/shared-components/form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { ROUTES } from "@constants";
import { handleUpdateAfterSignIn } from "@libs/api/auth";
import { GqlClient } from "@libs/graphql/client";
import { useEmailSignInReqMutation } from "@libs/graphql/generated-query";
import MixPanelClient from "@libs/mixpanel/mixpanelClient";
import { isDocumentTypeNameSameAs } from "@utils/graphql";

import AuthForm from "../components/AuthForm";
import AuthLayout from "../components/AuthLayout";

type SignInInput = {
  email: string;
  password: string;
};

function EmailSignInPage() {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignInInput>({
    mode: "onChange",
  });

  const [notificationMessage, setNotificationMessage] = useState("");

  const { mutateAsync } = useEmailSignInReqMutation(GqlClient);

  const handleSignIn = async (data: SignInInput) => {
    const signInResult = await mutateAsync({
      email: data.email,
      password: data.password,
    });

    if (isDocumentTypeNameSameAs(signInResult.emailSignIn, "NeedSignUpError")) {
      setNotificationMessage("Login failed.\nInvalid Email or Password.");
    }
    if (isDocumentTypeNameSameAs(signInResult.emailSignIn, "WrongPasswordError")) {
      setNotificationMessage("Login failed.\nInvalid Email or Password.");
    }
    if (isDocumentTypeNameSameAs(signInResult.emailSignIn, "WrongSignUpProviderError")) {
      setNotificationMessage("Login failed.\nThis email is registered with Google.");
    }
    if (isDocumentTypeNameSameAs(signInResult.emailSignIn, "RefreshResponse")) {
      handleUpdateAfterSignIn({ accessToken: signInResult.emailSignIn.accessToken });
      MixPanelClient.track("sign_in", { sign_in_method: "email" });
      history.push(ROUTES.MAIN);
    }
  };

  const handleInvalidInput = () => {
    setNotificationMessage("Login failed.\nInvalid Email or Password.");
  };

  const handleClickSignUp = () => {
    history.push(ROUTES.SIGN_UP);
  };

  const handleClickForgotPassword = () => {
    history.push(ROUTES.RESET_PASSWORD);
  };

  return (
    <AuthLayout goBackRoute={ROUTES.SIGN_IN}>
      <AuthForm
        notificationMessage={notificationMessage}
        headerComponent={
          <div className="flex items-center justify-center w-full">
            <p className="text-body-xl font-bold">Log in</p>
          </div>
        }
        bodyComponent={
          <form
            onSubmit={handleSubmit(handleSignIn, handleInvalidInput)}
            className="flex flex-col items-center w-full gap-6"
          >
            <div className="flex flex-col items-center w-full gap-1">
              <Input
                size="sm"
                label="Email"
                placeholder="example@email.com"
                className="w-75 h-[82px]"
                autoComplete="username"
                {...register("email", {
                  required: "You must enter your email address",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "That email is invalid",
                  },
                })}
              />
              <Input
                size="sm"
                inputType="password"
                label="Password"
                placeholder="Enter your password"
                className="w-75 h-[82px]"
                autoComplete="current-password"
                {...register("password", {
                  minLength: {
                    value: 8,
                    message: "Use a password between 8 and 32 characters in length",
                  },
                  maxLength: {
                    value: 32,
                    message: "Use a password between 8 and 32 characters in length",
                  },
                  required: {
                    value: true,
                    message: "You must enter your password",
                  },
                })}
              />
            </div>
            <div className="flex flex-col items-center w-full gap-3">
              <Button
                type="submit"
                label="Log in"
                size="lg"
                emphasis="primary"
                className="w-75"
                loading={isSubmitting}
              />
              <div className="w-53 text-center">
                <Button size="md" label="Forgot password?" emphasis="link" onClick={handleClickForgotPassword} />
              </div>
            </div>
            <div className="flex-center gap-2">
              <p className="font-semibold">Are you new user?</p>
              <Button type="button" label="Sign up" emphasis="link" size="md" onClick={handleClickSignUp} />
            </div>
          </form>
        }
      />
    </AuthLayout>
  );
}

export default EmailSignInPage;
