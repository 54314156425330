import { useEffect } from "react";

import { WINDOW_MESSAGES } from "@sonovel/shared-constants";
import { isDesktopApp } from "@sonovel/shared-utils";
import toast from "react-hot-toast";

import KeyboardShortcutHelpModalContent from "@components/Modal/KeyboardShorcutHelp/KeyboardShortcutHelpModalContent";
import { useDesktopActions } from "@stores/desktop";
import { useModalActions } from "@stores/modal";

type DesktopInitializerProps = {
  children: React.ReactNode;
};
const shouldInit = isDesktopApp();

function DesktopInitializer({ children }: DesktopInitializerProps) {
  const { setLocalBackupPath, enableLocalBackup, disableLocalBackup } = useDesktopActions();
  const { showModal } = useModalActions();

  useEffect(() => {
    if (!shouldInit) return;

    window.postMessage({ type: WINDOW_MESSAGES.GET_LOCAL_BACKUP_PATH }, window.location.origin);
    window.postMessage({ type: WINDOW_MESSAGES.GET_LOCAL_BACKUP_ENABLED }, window.location.origin);

    window.addEventListener("message", (event) => {
      switch (event.data.type) {
        case WINDOW_MESSAGES.SHOW_KEYBOARD_SHORTCUTS:
          showModal({
            modalContent: <KeyboardShortcutHelpModalContent />,
          });
          break;

        case WINDOW_MESSAGES.GET_LOCAL_BACKUP_ENABLED_REPLY:
          if (event.data.enabled === true) enableLocalBackup();
          else disableLocalBackup();
          break;

        case WINDOW_MESSAGES.GET_LOCAL_BACKUP_PATH_REPLY:
          setLocalBackupPath(event.data.appPath);
          break;

        case WINDOW_MESSAGES.DISABLE_LOCAL_BACKUP_REPLY:
          disableLocalBackup();
          break;

        case WINDOW_MESSAGES.ENABLE_LOCAL_BACKUP_REPLY:
          enableLocalBackup();
          break;

        case WINDOW_MESSAGES.CHANGE_LOCAL_BACKUP_PATH_REPLY:
          setLocalBackupPath(event.data.appPath);
          break;

        case WINDOW_MESSAGES.SAVE_LOCAL_BACKUP_FAIL_REPLY:
          toast(
            <p className="text-center">
              Local backup failed.
              <br />
              If it fails repeatedly, please contact us
            </p>
          );
          break;
      }
    });
  }, []);

  return <>{children}</>;
}

export default DesktopInitializer;
