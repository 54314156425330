export const EXTERNAL_LINK = {
  APP: "https://app.sonovel.io",
  LANDING: "https://sonovel.io",
  DOWNLOAD_DESKTOP_APP_PAGE: "https://sonovel.io/download",
  DISCORD: "https://discord.gg/Nu5CC4cuWc",
  DISCORD_IDEA_CHANNEL: "https://discord.gg/JXjpdHZf",
  DASHBOARD_BANNER: "https://forms.gle/2p962J5GSoDoWTHP7",
  HELP_CENTER: "https://atlas-kb.com/novela-studio-co-ltd",
  GETTING_STARTED: "https://atlas-kb.com/novela-studio-co-ltd/articles/414635-getting-started",
  KEYBOARD_SHORTCUTS:
    "https://atlas-kb.com/novela-studio-co-ltd/articles/229487-keyboard-shortcuts?SEARCHED_EVENT=shortcut",

  WINDOW_DESKTOP_APP_DOWNLOAD:
    "https://github.com/dev-novela/sonovel-desktop/releases/download/v0.0.2/Sonovel-0.0.2.Setup.exe",
  APPLE_SILICON_DESKTOP_APP_DOWNLOAD:
    "https://github.com/dev-novela/sonovel-desktop/releases/download/v0.0.2/Sonovel-0.0.2-arm64.dmg",
  INTEL_MAC_DESKTOP_APP_DOWNLOAD:
    "https://github.com/dev-novela/sonovel-desktop/releases/download/v0.0.2/Sonovel-0.0.2-x64.dmg",
  TERMS_OF_SERVICE: "https://novelastudio.notion.site/Terms-of-Service-37b794c791544c57aebcfbda0c45943b",
  PRIVACY_POLICY: "https://novelastudio.notion.site/Privacy-Policy-07ce49e90ca740beb154988a2a295974",
};
