import { useEffect, useRef } from "react";

import { isIos } from "@sonovel/shared-utils/userAgent";

// NOTE : iOS 환경에서 focus 된 영역 뒷부분이 scroll되지 않도록 하기 위해 사용
const useBodyScrollLock = ({ isOpened } = { isOpened: true }) => {
  const scrollTop = useRef(0);

  const lockBodyScroll = () => {
    const { body } = document;

    const pageY = window.pageYOffset;

    scrollTop.current = pageY;
    body.style.overflow = "hidden";
    body.style.position = "fixed";
    body.style.left = "0px";
    body.style.right = "0px";
    body.style.bottom = "0px";
    body.style.top = `-${pageY}px`;
  };

  const unlockBodyScroll = () => {
    const { body } = document;

    body.style.removeProperty("overflow");
    body.style.removeProperty("position");
    body.style.removeProperty("top");
    body.style.removeProperty("left");
    body.style.removeProperty("right");
    body.style.removeProperty("bottom");

    window.scrollTo(0, scrollTop.current);
    scrollTop.current = 0;
  };

  useEffect(
    function toggleBodyScrollLockOnMount() {
      if (!isIos()) return;
      if (isOpened) lockBodyScroll();
      return () => unlockBodyScroll();
    },
    [isOpened]
  );
};

export default useBodyScrollLock;
