type FilterByTypename<T, K> = T extends { __typename: K } ? T : never;

export type GraphqlResponse = { __typename: string };

export function isGraphQLResponse(response: unknown): response is GraphqlResponse {
  return typeof response === "object" && response !== null && "__typename" in response;
}

export function isDocumentTypeNameSameAs<T extends { __typename?: string }, K extends T["__typename"]>(
  data: T | undefined,
  key: K
): data is FilterByTypename<T, K> {
  return data !== undefined && (data["__typename"] as unknown) === key;
}

export function selectDto<T extends { __typename?: string }, K extends T["__typename"]>(
  data: T | undefined,
  key: K
): FilterByTypename<T, K> {
  if (!isDocumentTypeNameSameAs(data, key)) {
    throw data;
  }

  return data;
}
