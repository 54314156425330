import { ReactNode, useEffect, useState } from "react";

import Atlas from "@libs/atlas";
import { GqlClient } from "@libs/graphql/client";
import { useUserReqQuery } from "@libs/graphql/generated-query";
import { useAuthStatus } from "@stores/auth";
import { useIsMobileView } from "@stores/view";
import { isDocumentTypeNameSameAs, selectDto } from "@utils/graphql";

function AtlasUserSync({ children }: { children: ReactNode }) {
  const authStatus = useAuthStatus();
  const userRes = useUserReqQuery(GqlClient, undefined, { suspense: true, enabled: authStatus === "authorized" });
  const isMobileView = useIsMobileView();
  const [isBooted, setIsBooted] = useState(false);

  useEffect(
    function initAtlas() {
      if (isMobileView) return;
      if (!isBooted) {
        Atlas.boot();
        setIsBooted(true);
      }
    },
    [isMobileView]
  );

  useEffect(
    function syncAtlasUser() {
      if (authStatus === "authorized" && isDocumentTypeNameSameAs(userRes.data?.user, "UserResponse")) {
        const user = selectDto(userRes.data?.user, "UserResponse");

        if (!isBooted) return;

        Atlas.identify({ email: user.email, userId: user._id });
        return;
      }
      Atlas.hide();
    },
    [authStatus, userRes, isBooted]
  );

  return <>{children}</>;
}

export default AtlasUserSync;
