import { ReactNode, useEffect } from "react";

import * as Sentry from "@sentry/react";
import { isDesktopApp } from "@sonovel/shared-utils";

import { GqlClient } from "@libs/graphql/client";
import { useUserReqQuery } from "@libs/graphql/generated-query";
import MixPanelClient from "@libs/mixpanel/mixpanelClient";
import { useAuthStatus } from "@stores/auth";
import { isDocumentTypeNameSameAs, selectDto } from "@utils/graphql";

function MixpanelUserSync({ children }: { children: ReactNode }) {
  const authStatus = useAuthStatus();
  const userRes = useUserReqQuery(GqlClient, undefined, { suspense: true, enabled: authStatus === "authorized" });

  useEffect(
    function syncMixpanelUser() {
      if (authStatus === "authorized" && isDocumentTypeNameSameAs(userRes.data?.user, "UserResponse")) {
        const user = selectDto(userRes.data?.user, "UserResponse");
        const isDesktopUser = isDesktopApp();
        MixPanelClient.setUserInfo({ userId: user._id, email: user.email, isDesktop: isDesktopUser });
        Sentry.setUser({ id: user._id, email: user.email });
        return;
      }
      MixPanelClient.resetUser();
    },
    [authStatus, userRes]
  );

  return <>{children}</>;
}

export default MixpanelUserSync;
