import { lazy, useEffect } from "react";

import { focusManager } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { Switch, useLocation } from "react-router-dom";

import BaseModal from "@components/Modal/BaseModal";
import SecondModal from "@components/Modal/SecondModal";
import CustomPopover from "@components/Popover";
import { ROUTES } from "@constants";
import AtlasUserSync from "@hoc/AtlasUserSync";
import LoginChecker from "@hoc/LoginChecker";
import MixpanelUserSync from "@hoc/MixpanelUserSync";
import PrivateRoute from "@hoc/route/PrivateRoute";
import PublicRoute from "@hoc/route/PublicRoute";
import ViewSizeSelector from "@hoc/ViewSizeSelector";
import useCalculateScreenHeight from "@hooks/useCalculateScreenHeight";
import { useHandleAppMessages } from "@hooks/useHandleAppMessages.js";
import MixPanelClient from "@libs/mixpanel/mixpanelClient";
import windowFocusManager from "@models/windowFocusManager";
import NotifyPasswordResetVerificationPage from "@pages/Auth/callback/PasswordReset/NotifyPasswordResetVerificationPage";
import EmailSignInPage from "@pages/Auth/SignIn/EmailSignInPage";
import SignInMethodPage from "@pages/Auth/SignIn/SignInMethodPage";
import { useIsDarkMode } from "@stores/preference";
import routeUtils from "@utils/routeUtils";

const SignUpMethodPage = lazy(() => import("@pages/Auth/SignUp/SignUpMethodPage"));
const EmailSignUpPage = lazy(() => import("@pages/Auth/SignUp/EmailSignUpPage"));
const DashBoardPage = lazy(() => import("@pages/DashBoard/DashBoardPage"));
const StoryPage = lazy(() => import("@pages/Story/StoryPage"));
const NotifyEmailVerificationPage = lazy(
  () => import("@pages/Auth/callback/EmailVerification/NotifyEmailVerificationPage")
);
const EmailVerificationCallback = lazy(
  () => import("@pages/Auth/callback/EmailVerification/EmailVerificationCallback")
);
const OAuthCallback = lazy(() => import("@pages/Auth/callback/OAuthCallback"));
const PasswordResetCallback = lazy(() => import("@pages/Auth/callback/PasswordReset/PasswordResetCallback"));

focusManager.setEventListener(windowFocusManager.onWindowFocus);

function App() {
  const location = useLocation();
  const isDarkMode = useIsDarkMode();
  useCalculateScreenHeight();
  useHandleAppMessages();

  useEffect(
    function trackPageView() {
      if (routeUtils.isStoryRoute()) return;
      MixPanelClient.trackPageView();
    },
    [location]
  );

  return (
    <ViewSizeSelector>
      <LoginChecker>
        <MixpanelUserSync>
          <AtlasUserSync>
            <Switch>
              <PrivateRoute path={ROUTES.MAIN} exact component={DashBoardPage} />
              <PrivateRoute path={ROUTES.STORY_ENTITY()} component={StoryPage} />
              <PrivateRoute path={ROUTES.NOTIFY_EMAIL_VERIFICATION} exact component={NotifyEmailVerificationPage} />
              <PublicRoute
                path={ROUTES.VERIFY_EMAIL}
                canSignInUserAccess={true}
                exact
                component={EmailVerificationCallback}
              />
              <PublicRoute
                path={ROUTES.RESET_PASSWORD}
                canSignInUserAccess={true}
                exact
                component={PasswordResetCallback}
              />
              <PublicRoute path={ROUTES.SIGN_IN} canSignInUserAccess={false} exact component={SignInMethodPage} />
              <PublicRoute path={ROUTES.SIGN_UP} canSignInUserAccess={false} exact component={SignUpMethodPage} />
              <PublicRoute path={ROUTES.EMAIL_SIGN_UP} canSignInUserAccess={false} exact component={EmailSignUpPage} />
              <PublicRoute path={ROUTES.EMAIL_SIGN_IN} canSignInUserAccess={false} exact component={EmailSignInPage} />
              <PublicRoute
                path={ROUTES.NOTIFY_RESET_PASSWORD_VERIFICATION}
                canSignInUserAccess={false}
                exact
                component={NotifyPasswordResetVerificationPage}
              />
              <PublicRoute path={ROUTES.OAUTH_CALLBACK} exact component={OAuthCallback} />
            </Switch>

            {/** Other components */}
            <BaseModal />
            <SecondModal />
            <CustomPopover />
            <Toaster
              containerClassName="break-all md:mb-5 font-medium text-body-sm"
              toastOptions={{
                style: {
                  color: isDarkMode ? "#ededed" : "#424242",
                  backgroundColor: isDarkMode ? "#30393f" : "#ffffff",
                },
              }}
              position="bottom-center"
            />
          </AtlasUserSync>
        </MixpanelUserSync>
      </LoginChecker>
    </ViewSizeSelector>
  );
}

export default App;
