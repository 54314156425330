import { GraphQLClient } from "graphql-request";
import { RequestMiddleware } from "graphql-request/dist/types";

import { getAuthAccessToken } from "@stores/auth";

const requestMiddleware: RequestMiddleware = async (request) => {
  let accessToken = getAuthAccessToken();

  if (window.App) {
    accessToken = window.App.getAccessToken();
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
      "apollo-require-preflight": "true",
    },
  };
};

export const GqlClient = new GraphQLClient(CONFIG.ENDPOINT.API, {
  credentials: "include",
  requestMiddleware,
});
