import { Button } from "@sonovel/shared-components";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import EmailImage from "@assets/images/mail.png";
import { GqlClient } from "@libs/graphql/client";
import { useSendResetPasswordMailReqMutation } from "@libs/graphql/generated-query";
import AuthForm from "@pages/Auth/components/AuthForm";
import AuthLayout from "@pages/Auth/components/AuthLayout";
import { isDocumentTypeNameSameAs } from "@utils/graphql";

type NotifyPasswordResetVerificationPageLocationProps = {
  email?: string;
};

function NotifyPasswordResetVerificationPage() {
  const { state } = useLocation<NotifyPasswordResetVerificationPageLocationProps>();
  const { mutateAsync, isLoading } = useSendResetPasswordMailReqMutation(GqlClient);

  const handleClickResend = async () => {
    if (!state?.email) {
      toast("Resend is unavailable. Please start from the beginning.");
      return;
    }

    const { sendResetPasswordMail } = await mutateAsync({ email: state.email });

    if (isDocumentTypeNameSameAs(sendResetPasswordMail, "SuccessFailureResponse")) {
      toast(
        <p>
          We’ve resent the link to <span className="text-success-600">{state.email}</span> to finish your account setup.
        </p>
      );
      return;
    }

    toast("Please try again. Email resend failed.");
  };

  return (
    <AuthLayout>
      <AuthForm
        headerComponent={
          <div className="flex flex-col items-center gap-2">
            <div className="w-30 h-33 p-5">
              <img src={EmailImage} className="h-23 w-20" />
            </div>
            <p className="text-body-xl font-bold">Check your inbox</p>
          </div>
        }
        bodyComponent={
          <div className="flex flex-col items-center gap-10">
            <div className="text-body-sm w-75 flex flex-col gap-3 font-medium text-center">
              <p>
                We sent instructions to{" "}
                <span className="text-success-600 font-bold">{state?.email ?? "your email"}</span> to reset your
                password
              </p>
              <p>Please check both your inbox and spam folder.</p>
            </div>
            <div className="flex flex-col items-center gap-1">
              <p className="text-body-xs font-medium text-gray-700">No email in your inbox or spam folder?</p>
              <Button
                label="Let's resend it"
                emphasis="link"
                size="sm"
                onClick={handleClickResend}
                disabled={isLoading}
              />
            </div>
          </div>
        }
      />
    </AuthLayout>
  );
}

export default NotifyPasswordResetVerificationPage;
