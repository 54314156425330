import { useEffect } from "react";

import classNames from "classnames";

import { withAsyncErrorBoundary } from "@components/Error/AsyncErrorBoundary";
import ReactPortal from "@components/ReactPortal";
import useBodyScrollLock from "@hooks/useBodyScrollLock";
import { useModalActions, useModalState } from "@stores/modal";

function BaseModal() {
  const { show, modalContent, modalOption } = useModalState();
  const { closeModal } = useModalActions();
  useBodyScrollLock({ isOpened: show });

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape" && modalOption.canClickBackDrop) {
      closeModal();
    }
  };

  useEffect(
    function addKeyDownEventListener() {
      if (!show) return;
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    },
    [show]
  );

  const handleOnClickOuterComponent = () => {
    if (modalOption.canClickBackDrop) closeModal();
  };

  if (!show) return null;

  return (
    <ReactPortal>
      <section
        className="bg-black-7 dark:bg-white-7 fixed inset-x-0 inset-y-0 z-40 flex items-center justify-center w-screen h-screen"
        onMouseDown={handleOnClickOuterComponent}
        onTouchEnd={handleOnClickOuterComponent}
      >
        <div
          className={classNames(
            "min-h-33 bg-white-0 shadow-5 custom-scroll max-h-screen p-0 overflow-hidden overflow-y-auto rounded-lg flex",
            modalOption.shouldRenderFullWhenMobile &&
              "rounded-none h-full w-full flex justify-center md:h-auto md:w-auto md:rounded-lg"
          )}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          {modalContent}
        </div>
      </section>
    </ReactPortal>
  );
}

const BaseModalWithAsyncErrorBoundary = withAsyncErrorBoundary(BaseModal);

export default BaseModalWithAsyncErrorBoundary;
