import { FocusEvent, forwardRef, MouseEvent, Ref, useCallback, useMemo, useRef, useState } from "react";

import { mergeRefs } from "@sonovel/shared-utils";
import classNames from "classnames";

import { InputProps } from "./Input.types";

import "./Input.css";

export const Input = forwardRef(function Input(
  {
    type = "container",
    disabled = false,
    leftIcon,
    rightIcon,
    className,
    suffixLabel,
    size = "md",
    label,
    helpText,
    onBlur,
    error,
    onFocus,
    inputClassName,
    inputType = "text",
    defaultBorderClassName = "border-gray-600",
    ...props
  }: InputProps,
  forwardedRef: Ref<HTMLInputElement>
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);

  const onClickInputFocusArea = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onClickIconContainer = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      if (onFocus) onFocus(e);
    },
    [onFocus]
  );

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement, Element>) => {
      setFocused(false);
      if (onBlur) onBlur(e);
    },
    [onBlur]
  );

  const isContainerType = useMemo(() => type === "container", [type]);
  const isLineType = useMemo(() => type === "line", [type]);

  return (
    <div
      className={classNames("inline-flex flex-col items-start", className, {
        [`input-${size}`]: true,
        [`input-type-${type}`]: true,
        ["text-gray-500"]: disabled,
      })}
    >
      {label && (
        <p
          className={classNames("input-label font-medium", {
            ["text-gray-800"]: !disabled,
            ["text-gray-500"]: disabled,
          })}
        >
          {label}
        </p>
      )}
      <div
        className={classNames(
          "input-container self-stretch flex items-center px-1",
          {
            ["border-[1.5px] rounded"]: isContainerType,
            ["border-b"]: isLineType,
          },
          disabled && "cursor-default border-gray-500",
          !disabled && {
            ["border-error-600"]: error,
            [defaultBorderClassName]: !error && !focused,
            ["border-success-600 text-success-600"]: !error && focused,
            ["ring-success-300"]: !error,
            ["ring-error-300"]: error,
          }
        )}
      >
        {leftIcon && (
          <div className={classNames("input-icon", isContainerType && "ml-2")} onClick={onClickIconContainer}>
            {leftIcon}
          </div>
        )}
        <div className="gap-x-2 flex justify-between w-full h-full px-2" onClick={onClickInputFocusArea}>
          <input
            type={inputType}
            spellCheck={false}
            disabled={disabled}
            onFocus={handleOnFocus}
            className={classNames(
              "input flex-grow bg-transparent h-full w-full p-0 border-none outline-none ring-0",
              {
                ["text-gray-900 placeholder:text-gray-600"]: !disabled,
              },
              inputClassName
            )}
            ref={mergeRefs([inputRef, forwardedRef])}
            onBlur={handleOnBlur}
            {...props}
          />
          {suffixLabel && (
            <div className="inline-flex items-center h-full">
              <p
                className={classNames("font-medium text-body-xs", {
                  ["text-gray-500"]: disabled,
                  ["text-gray-600"]: !disabled && !focused,
                  ["text-gray-800"]: !disabled && focused,
                })}
              >
                {suffixLabel}
              </p>
            </div>
          )}
        </div>

        {rightIcon && (
          <div className={classNames("input-icon", isContainerType && "mr-2")} onClick={onClickIconContainer}>
            {rightIcon}
          </div>
        )}
      </div>
      {helpText && (
        <div
          className={classNames("input-help-text flex items-center justify-end", {
            ["text-gray-500"]: disabled,
            ["text-gray-700"]: !error && !disabled,
            ["text-error-600"]: error,
          })}
        >
          <p>{helpText}</p>
        </div>
      )}
    </div>
  );
});
