import { OAuthConfig } from "./OAuthConfig";

export type OAuthProvider = "google" | "apple";

export type AuthenticateParamsState = {
  botId?: string;
};

export type AuthenticateParams = {
  state?: AuthenticateParamsState;
};

export default class OAuthClient {
  config: (typeof OAuthConfig)[keyof typeof OAuthConfig];

  provider: OAuthProvider;

  constructor(provider: OAuthProvider) {
    this.provider = provider;
    this.config = OAuthConfig[provider];
  }

  public async authenticate({ state = {} }: AuthenticateParams = {}) {
    let result: any;
    switch (this.provider) {
      case "google":
        this.authenticateGoogleUser(state);
        break;
      case "apple":
        result = await this.authenticateAppleUser();
        break;
      default:
        throw new Error("Invalid OAuth provider");
    }

    return result;
  }

  private async authenticateAppleUser() {
    window.AppleID.auth.init({
      clientId: this.config.clientId,
      redirectURI: this.config.callbackUrl,
      scope: this.config.scope,
      usePopup: true,
    });
    return await window.AppleID.auth.signIn();
  }

  private async authenticateGoogleUser(state = {}) {
    const { authorizationUrl, clientId, callbackUrl, scope } = this.config;
    const params = new URLSearchParams({
      client_id: clientId,
      response_type: "code",
      redirect_uri: callbackUrl,
      state: JSON.stringify(state),
    });

    if (scope) {
      params.set("scope", scope);
    }
    params.set("access_type", "offline");
    params.set("prompt", "consent");

    const authUrl = `${authorizationUrl}?${params.toString()}`;
    window.location.href = authUrl;
  }
}
