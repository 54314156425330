function Spinner() {
  return (
    <div className="z-20 flex items-center justify-center w-full h-full">
      <span
        className="animate-spinner w-2.5 h-2.5 text-[10px] rounded-[50%] relative"
        style={{
          transform: "translateZ(0)",
        }}
      />
    </div>
  );
}

export default Spinner;
