import { CheckboxProps } from "./Checkbox.types";

type CheckboxIconType = {
  size: CheckboxProps["size"];
};

function CheckboxIcon({ size }: CheckboxIconType) {
  switch (size) {
    case "sm":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M9.73339 3.20004L4.60006 8.33338L2.26672 6.00004"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "md":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
          <path
            d="M11.1999 3.85004L5.42493 9.62504L2.79993 7.00004"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "lg":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
          <path
            d="M11.6666 3.5L5.24992 9.91667L2.33325 7"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}

export default CheckboxIcon;
