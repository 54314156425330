import React, { forwardRef } from "react";

import classNames from "classnames";

import { ButtonType } from "./Button.types";
import "./Button.css";
import ButtonLoadingSpinner from "./ButtonLoadingSpinner";

export const Button = forwardRef(function Button(
  {
    type = "button",
    size = "md",
    state = "normal",
    disabled = false,
    emphasis = "primary",
    label,
    loading,
    className,
    leftIcon,
    rightIcon,
    labelClassname,
    ...props
  }: ButtonType,
  forwardedRef: React.Ref<HTMLButtonElement>
) {
  const shouldDisable = disabled || loading;

  return (
    <button
      ref={forwardedRef}
      type={type}
      disabled={shouldDisable}
      className={classNames("btn", className, {
        [`btn-${size}`]: true,
        [`btn-${emphasis}`]: !shouldDisable,
        [`btn-${emphasis}-disabled`]: shouldDisable,
        [`${state}`]: state !== "normal" && !shouldDisable,
      })}
      {...props}
    >
      {loading ? (
        <ButtonLoadingSpinner />
      ) : (
        <>
          {leftIcon && leftIcon}
          {label && <p className={classNames("label", labelClassname)}>{label}</p>}
          {rightIcon && rightIcon}
        </>
      )}
    </button>
  );
});
