import { useStore } from "zustand";
import { createStore } from "zustand/vanilla";

import { AuthStoreState } from "./types";

export const authStore = createStore<AuthStoreState>((set) => ({
  accessToken: null,
  authStatus: "pending",

  actions: {
    setAccessToken: (accessToken) => set((state) => ({ ...state, accessToken })),
    setAuthStatus: (authStatus) => set((state) => ({ ...state, authStatus })),
    signOut: () => set({ accessToken: null, authStatus: "unauthorized" }),
  },
}));

export const getAuthAccessToken = () => authStore.getState().accessToken;
export const getAuthStatus = () => authStore.getState().authStatus;
export const getAuthActions = () => authStore.getState().actions;

// NOTE: use this if you want to access auth store in react component
const useAuthStore = () => useStore(authStore);
export const useAuthStatus = () => useAuthStore().authStatus;
