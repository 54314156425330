function SignMethodDivider() {
  return (
    <div className="w-75 flex items-center gap-1">
      <div className="grow h-0.5 bg-gray-300" />
      <div className="flex-center h-7 w-6 p-1">
        <p className="text-body-sm text-gray-500">Or</p>
      </div>
      <div className="grow h-0.5 bg-gray-300" />
    </div>
  );
}

export default SignMethodDivider;
