import { cmdOrCtrlKey, optOrAltKey } from "@sonovel/shared-utils";

import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import IconContainer from "@components/IconContainer";
import { useModalActions } from "@stores/modal";

import KeyboardShortcutHelpSection from "./KeyboardShortcutHelpSection";

function KeyboardShortcutHelpModalContent() {
  const { closeModal } = useModalActions();

  return (
    <div className="w-84 lg:w-[800px] flex flex-col gap-5 lg:gap-8  pt-6 max-h-[80vh]">
      <div className="lg:px-6 flex w-full px-3">
        <p className="text-heading-xs md:self-start flex-grow font-bold">Keyboard Shortcuts</p>
        <IconContainer className="w-7 h-7" icon={<CloseIcon />} onClick={closeModal} />
      </div>

      <div className="custom-scroll lg:gap-15 lg:px-6 lg:pb-15 lg:flex flex flex-wrap gap-6 px-3 pb-6 overflow-y-auto">
        <div className="flex flex-col gap-6">
          <KeyboardShortcutHelpSection
            title="General"
            shortcuts={[{ name: "Switch between light and dark mode", keys: [cmdOrCtrlKey, "Shift", "L"] }]}
          />
          <KeyboardShortcutHelpSection
            title="Editor"
            shortcuts={[
              { name: "Search", keys: [cmdOrCtrlKey, "Shift", "F"] },
              { name: "Create a new history version", keys: [cmdOrCtrlKey, optOrAltKey, "S"] },
              { name: "Turn on/off typewriter mode", keys: [cmdOrCtrlKey, optOrAltKey, "T"] },
              { name: "Open/Close Manuscript side bar", keys: [cmdOrCtrlKey, "/"] },
            ]}
          />
        </div>
        <div className="flex flex-col gap-6">
          <KeyboardShortcutHelpSection
            title="Split view"
            shortcuts={[{ name: "Split/Unite", keys: [cmdOrCtrlKey, "\\"] }]}
          />
          <KeyboardShortcutHelpSection
            title="Text style"
            shortcuts={[
              { name: "Bold", keys: [cmdOrCtrlKey, "B"] },
              { name: "Italic", keys: [cmdOrCtrlKey, "I"] },
              { name: "Underline", keys: [cmdOrCtrlKey, "U"] },
              { name: "Strikethrough", keys: [cmdOrCtrlKey, "Shift", "X"] },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default KeyboardShortcutHelpModalContent;
