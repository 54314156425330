import { Redirect, Route, RouteProps } from "react-router";

import { ROUTES } from "@constants";
import { GqlClient } from "@libs/graphql/client";
import { useUserReqQuery } from "@libs/graphql/generated-query";
import { useAuthStatus } from "@stores/auth";
import { selectDto } from "@utils/graphql";

type PrivateRouteProps = RouteProps<string>;

function PrivateRoute({ component, path, ...rest }: PrivateRouteProps) {
  const authStatus = useAuthStatus();

  if (authStatus === "pending") return null;
  if (authStatus === "authorized") {
    return <AuthorizedRoute path={path} component={component} {...rest} />;
  }

  return <Redirect to={ROUTES.SIGN_IN} />;
}

export const AuthorizedRoute = ({ component, path, ...rest }: PrivateRouteProps) => {
  const { data: user } = useUserReqQuery(GqlClient, {}, { suspense: true, select: (data) => data.user });

  const userDto = selectDto(user, "UserResponse");

  const isEmailVerified = userDto.isEmailVerified;

  if (isEmailVerified || path === ROUTES.NOTIFY_EMAIL_VERIFICATION) {
    return (
      <>
        <Route {...rest} path={path} component={component} />
      </>
    );
  }

  return <Redirect to={ROUTES.NOTIFY_EMAIL_VERIFICATION} />;
};

export default PrivateRoute;
