import { Button } from "@sonovel/shared-components";
import { useHistory } from "react-router-dom";

import { ROUTES } from "@constants";
import OAuthClient from "@libs/OAuth/OAuthClient";

import AuthForm from "../components/AuthForm";
import AuthLayout from "../components/AuthLayout";
import SignButton from "../components/SignButton";
import SignMethodDivider from "../components/SignMethodDivider";
import { useHandleAppleSign } from "../hooks/useHandleAppleSignIn";

function SignInMethodPage() {
  const history = useHistory();
  const { handleClickAppleSign } = useHandleAppleSign();

  const handleClickGoogleSignIn = () => {
    new OAuthClient("google").authenticate();
  };

  const handleClickEmailSignIn = () => {
    history.push(ROUTES.EMAIL_SIGN_IN);
  };

  const handleClickSignUp = () => {
    history.push(ROUTES.SIGN_UP);
  };

  return (
    <AuthLayout>
      <AuthForm
        headerComponent={
          <div className="flex items-center justify-center w-full">
            <p className="text-body-xl font-bold">Log in</p>
          </div>
        }
        bodyComponent={
          <div className="flex flex-col items-center w-full gap-5">
            <SignButton onClick={handleClickAppleSign} type="signIn" method="apple" />
            <SignButton onClick={handleClickGoogleSignIn} type="signIn" method="google" />
            <SignMethodDivider />
            <div className="flex flex-col items-center gap-5">
              <SignButton onClick={handleClickEmailSignIn} type="signIn" method="email" />
              <div className="flex-center gap-2">
                <p className="font-semibold">Are you a new user?</p>
                <Button label="Sign up" emphasis="link" size="md" onClick={handleClickSignUp} />
              </div>
            </div>
          </div>
        }
      />
    </AuthLayout>
  );
}

export default SignInMethodPage;
