import KeyboardShortcutHelpRow from "./KeyboardShortcutHelpRow";

type Props = {
  title: string;
  shortcuts: { name: string; keys: string[] }[];
};

function KeyboardShortcutHelpSection({ title, shortcuts }: Props) {
  return (
    <div className="flex flex-col gap-3">
      <p className="text-body-lg font-bold">{title}</p>
      <div className="lg:gap-4 flex flex-col gap-3">
        {shortcuts.map((shortcut) => (
          <KeyboardShortcutHelpRow key={shortcut.name} name={shortcut.name} keys={shortcut.keys} />
        ))}
      </div>
    </div>
  );
}

export default KeyboardShortcutHelpSection;
