import cn from "classnames";

type SendLoaderProps = {
  classNames?: string;
};

function ButtonLoadingSpinner({ classNames }: SendLoaderProps) {
  return (
    <div className={cn("flex items-center w-5 h-5 bubbleContainer justify-center gap-x-[3px] ", classNames)}>
      <div className="w-1 h-1 md:w-[5px] md:h-[5px] animate-button-loading-spinner-1st-dot bg-gray-700 rounded-full "></div>
      <div className="w-1 h-1 md:w-[5px] md:h-[5px] animate-button-loading-spinner-2nd-dot bg-gray-700 rounded-full "></div>
      <div className="w-1 h-1 md:w-[5px] md:h-[5px] animate-button-loading-spinner-3rd-dot bg-gray-700 rounded-full "></div>
    </div>
  );
}
export default ButtonLoadingSpinner;
