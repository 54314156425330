import trim from "lodash/trim";
import uniqueId from "lodash/uniqueId";

export type TextReplaceEntity = {
  id: string;
  target: string;
  replacement: string;
};

class TextReplacePreference {
  private textReplacePreferenceMap: { [target: string]: string };

  constructor(pref: string) {
    this.textReplacePreferenceMap = JSON.parse(pref);
  }

  private preserveSpace(text: string) {
    const reg = /\s{2}/gi;

    return text.replaceAll(reg, "&nbsp; ");
  }

  hasText(text: string) {
    return this.textReplacePreferenceMap[text];
  }

  addText(targetText: string, replacingText: string) {
    const targetWithTrim = trim(targetText);

    if (!targetWithTrim || !replacingText) return this;

    this.textReplacePreferenceMap[targetWithTrim] = replacingText;
    return this;
  }

  deleteText(targetText: string) {
    if (this.textReplacePreferenceMap[targetText]) delete this.textReplacePreferenceMap[targetText];
    return this;
  }

  get count() {
    return this.toEntities().length;
  }

  toJSON() {
    return JSON.stringify(this.textReplacePreferenceMap);
  }

  toEntities(): TextReplaceEntity[] {
    return Object.entries(this.textReplacePreferenceMap).map(([target, replacement]) => {
      return { id: uniqueId("text-replace-"), target, replacement };
    });
  }

  toConfig() {
    return Object.entries(this.textReplacePreferenceMap).map(([target, replacement]) => ({
      start: target,
      replacement,
    }));
  }

  static fromEntities(entities: TextReplaceEntity[]) {
    const pref = new TextReplacePreference("{}");

    entities.forEach(({ target, replacement }) => {
      pref.addText(target, replacement);
    });

    return pref;
  }
}

export default TextReplacePreference;
