import { ROUTES } from "@constants";
import { StoryEntityTableType } from "@pages/Story/StoryEntityTable/types";

const getStoryEntityTableRoute = ({
  storyId,
  type = "empty",
  entityId,
}: {
  storyId?: string;
  type?: StoryEntityTableType;
  entityId?: string;
}) => {
  const path = `${ROUTES.STORY(storyId)}/${type}${entityId ? `/${entityId}` : ""}`;

  return path;
};

const isStoryRoute = () => {
  return window.location.pathname.includes("story");
};

const getUrlWithUTM = ({
  url,
  source,
  medium,
  campaign,
}: {
  url: string;
  source?: string;
  medium?: string;
  campaign?: string;
}) => {
  return `${url}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`;
};

export default { getStoryEntityTableRoute, isStoryRoute, getUrlWithUTM };
