/* eslint-disable prefer-rest-params */

declare global {
  interface Window {
    Atlas: any;
  }
}

interface AtlasConfig {
  email: string;
  userId: string;
}

class AtlasService {
  isBooted = false;

  constructor() {
    this.loadScript();
  }

  private loadScript() {
    "use strict";
    let t,
      // eslint-disable-next-line prefer-const
      e: { q: any; appId: string; v: number; call: () => void } = {
        appId: import.meta.env.VITE_ATLAS_APP_ID,
        v: 2,
        q: [],
        call: function () {
          this.q.push(arguments);
        },
      };
    window.Atlas = e;
    const n = document.createElement("script");
    (n.async = !0), (n.src = "https://app.atlas.so/client-js/atlas.bundle.js");
    const s = document.getElementsByTagName("script")[0];
    null === (t = s.parentNode) || void 0 === t || t.insertBefore(n, s);
  }

  public boot() {
    if (!window.Atlas || this.isBooted) return;

    window.Atlas.call("start", {
      recording: {
        enabled: false,
      },
    });

    this.isBooted = true;
  }

  public identify(config: AtlasConfig) {
    if (!window.Atlas) return;

    window.Atlas.call("identify", {
      email: config.email,
      userId: config.userId,
    });
  }

  public hide() {
    if (!window.Atlas || !window.Atlas.logout) return;

    window.Atlas.logout();
  }
}

export default new AtlasService();
