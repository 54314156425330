import { create } from "zustand";
import { combine } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { OpenPopoverPayload, PopoverStoreState } from "./types";

const initialTransformOrigin = {
  horizontal: "left",
  vertical: "top",
} as const;

const initialPopoverStoreState: PopoverStoreState = {
  show: false,
  position: {
    x: 0,
    y: 0,
  },
  transformOrigin: initialTransformOrigin,
  popoverContent: null,
};

const usePopoverStore = create(
  immer(
    combine(initialPopoverStoreState, (set) => ({
      actions: {
        openPopover: ({ x, y, content, transformOrigin = {}, name }: OpenPopoverPayload) =>
          set((state) => {
            state.show = true;
            state.name = name;
            state.position = { x, y };
            state.popoverContent = content;
            state.transformOrigin = {
              ...initialTransformOrigin,
              ...transformOrigin,
            };
          }),
        closePopover: () => set(initialPopoverStoreState),
      },
    }))
  )
);

export const usePopoverState = () =>
  usePopoverStore((state) => ({
    show: state.show,
    position: state.position,
    popoverContent: state.popoverContent,
    name: state.name,
    transformOrigin: state.transformOrigin,
  }));

export const usePopoverActions = () => usePopoverStore((state) => state.actions);
