import { EXTERNAL_LINK } from "@sonovel/shared-constants";
import { openExternalLink } from "@sonovel/shared-utils";

import { ReactComponent as SonovelImageLogo } from "@assets/images/sonovelImgLogo.svg";
import { ReactComponent as SonovelTextLogo } from "@assets/images/sonovelTextLogo.svg";

type Props = {
  headerComponent: React.ReactNode;
  bodyComponent: React.ReactNode;
  notificationMessage?: string;
};

function AuthForm({ headerComponent, bodyComponent, notificationMessage }: Props) {
  return (
    <div className="pb-15 grow flex flex-col justify-center w-full">
      {headerComponent}
      <div className="h-18 flex flex-col items-center justify-center">
        {notificationMessage && (
          <div className="h-13 w-75 bg-error-200 dark:bg-error-700 flex items-center justify-center p-2 text-center whitespace-pre-line">
            <p className="text-error-500 text-body-xs dark:text-error-900 font-medium">{notificationMessage}</p>
          </div>
        )}
      </div>
      {bodyComponent}
      <div className="h-18" />
      <button onClick={() => openExternalLink(EXTERNAL_LINK.LANDING)} className="flex gap-2.5 justify-center">
        <SonovelImageLogo className="w-4 h-4" />
        <SonovelTextLogo className="w-17 h-4" />
      </button>
    </div>
  );
}

export default AuthForm;
