import { forwardRef, MouseEvent, Ref, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { mergeRefs } from "@sonovel/shared-utils";
import classNames from "classnames";

import { MenuProps } from "./Menu.types";

type MenuPosition = {
  left: number;
  top: number;
};

export const Menu = forwardRef(function Menu(
  {
    open,
    anchorOrigin = { vertical: "bottom", horizontal: "left" },
    transformOrigin = { vertical: "top", horizontal: "left" },
    scrollable = {
      horizontal: false,
      vertical: false,
    },
    children,
    onClose,
    className,
    anchorElement,
    ...props
  }: MenuProps,
  forwardedRef: Ref<HTMLDivElement>
) {
  const [position, setPosition] = useState<MenuPosition>({ left: 0, top: 0 });
  const [menuWidth, setMenuWidth] = useState(0);
  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);

  const [scrollableWidth, setScrollableWidth] = useState(0);
  const [scrollableHeight, setScrollableHeight] = useState(0);

  const isHorizontallyScrollable = scrollable.horizontal === true;
  const isVerticallyScrollable = scrollable.vertical === true;

  const calculateMenuPosition = useCallback(
    (box: DOMRect) => {
      const { width: clientWidth, height: clientHeight } = window.visualViewport as VisualViewport;

      let top: number;
      let left: number;

      switch (true) {
        case anchorOrigin.vertical === "top":
          if (box.top + menuHeight > clientHeight) {
            if (isVerticallyScrollable) {
              setScrollableHeight(clientHeight - box.top);
            } else {
              top = box.top - menuHeight;
              break;
            }
          }

          switch (true) {
            case transformOrigin.vertical === "bottom":
              top = box.top - menuHeight;
              break;
            case transformOrigin.vertical === "center":
              top = box.top - menuHeight / 2;
              break;
            default:
            case transformOrigin.vertical === "top":
              top = box.top;
              break;
          }

          break;
        case anchorOrigin.vertical === "center":
          if (box.top + menuHeight + box.height / 2 > clientHeight) {
            if (isVerticallyScrollable) {
              setScrollableHeight(clientHeight - box.top - box.height / 2);
            } else {
              top = box.top + box.height / 2 - menuHeight;
              break;
            }
          }

          switch (true) {
            case transformOrigin.vertical === "bottom":
              top = box.top + box.height / 2 - menuHeight;
              break;
            case transformOrigin.vertical === "center":
              top = box.top + box.height / 2 - menuHeight / 2;
              break;
            default:
            case transformOrigin.vertical === "top":
              top = box.top + box.height / 2;
              break;
          }

          break;

        default:
        case anchorOrigin.vertical === "bottom":
          if (box.top + menuHeight + box.height > clientHeight) {
            if (isVerticallyScrollable) {
              setScrollableHeight(clientHeight - box.top - box.height);
            } else {
              top = box.top + box.height - menuHeight;
              break;
            }
          }

          switch (true) {
            case transformOrigin.vertical === "bottom":
              top = box.top + box.height - menuHeight;
              break;
            case transformOrigin.vertical === "center":
              top = box.top + box.height - menuHeight / 2;
              break;
            default:
            case transformOrigin.vertical === "top":
              top = box.top + box.height;
              break;
          }

          break;
      }

      switch (true) {
        case anchorOrigin.horizontal === "right":
          if (box.left + menuWidth + box.width > clientWidth) {
            if (isHorizontallyScrollable) {
              setScrollableWidth(clientWidth - box.right);
            } else {
              left = box.right - menuWidth > 0 ? box.right - menuWidth : 0;
              break;
            }
          }

          switch (true) {
            case transformOrigin.horizontal === "right":
              left = box.right - menuWidth;
              break;
            case transformOrigin.horizontal === "center":
              left = box.right - menuWidth / 2;
              break;
            default:
            case transformOrigin.horizontal === "left":
              left = box.right;
              break;
          }

          break;

        case anchorOrigin.horizontal === "center":
          if (box.left + menuWidth + box.width / 2 > clientWidth) {
            if (isHorizontallyScrollable) {
              setScrollableWidth(clientWidth - box.left - box.width / 2);
            } else {
              left = box.left + box.width / 2 - menuWidth;
              break;
            }
          }

          switch (true) {
            case transformOrigin.horizontal === "right":
              left = box.left + box.width / 2 - menuWidth;
              break;
            case transformOrigin.horizontal === "center":
              left = box.left + box.width / 2 - menuWidth / 2;
              break;
            default:
            case transformOrigin.horizontal === "left":
              left = box.left + box.width / 2;
              break;
          }

          break;

        default:
        case anchorOrigin.horizontal === "left":
          if (box.left + menuWidth > clientWidth) {
            if (isHorizontallyScrollable) {
              setScrollableWidth(clientWidth - box.left);
            } else {
              left = box.left - menuWidth;
              break;
            }
          }

          switch (true) {
            case transformOrigin.horizontal === "right":
              left = box.left - menuWidth;
              break;
            case transformOrigin.horizontal === "center":
              left = box.left - menuWidth / 2;
              break;
            default:
            case transformOrigin.horizontal === "left":
              left = box.left;
              break;
          }

          break;
      }
      return { top, left };
    },
    [
      anchorOrigin.vertical,
      anchorOrigin.horizontal,
      menuHeight,
      isVerticallyScrollable,
      menuWidth,
      isHorizontallyScrollable,
    ]
  );

  const updateMenuPosition = useCallback(() => {
    if (anchorElement) {
      const box = anchorElement.getBoundingClientRect();

      if (box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0) {
        throw new Error("invalid anchor element");
      }

      const { top, left } = calculateMenuPosition(box);

      setPosition({ top, left });
    }
  }, [anchorElement, calculateMenuPosition]);

  useLayoutEffect(
    function checkMenuWidth() {
      if (menuWidth || !menuRef.current) return;
      const { width, height } = menuRef.current.getBoundingClientRect();

      setMenuWidth(width);
      setMenuHeight(height);
      setScrollableHeight(0);
      setScrollableWidth(0);
    },
    [children]
  );

  useEffect(
    function updateMenuPositionOnResize() {
      if (!anchorElement) return;
      updateMenuPosition();
      window.addEventListener("resize", updateMenuPosition);
      window.addEventListener("scroll", updateMenuPosition);
      return () => {
        window.removeEventListener("resize", updateMenuPosition);
        window.removeEventListener("scroll", updateMenuPosition);
      };
    },
    [anchorElement, updateMenuPosition]
  );

  const onCloseMenu = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClose && onClose();
    },
    [onClose]
  );

  const isPositionCalculated = position.top !== 0 || position.left !== 0;

  const menuSizeStyle = {
    ...(scrollableHeight ? { height: scrollableHeight } : {}),
    ...(scrollableWidth ? { width: scrollableWidth } : {}),
  };

  return (
    open &&
    anchorElement && (
      <div className="fixed inset-0 z-50 w-screen h-screen" onClick={onCloseMenu}>
        <div
          ref={mergeRefs([forwardedRef, menuRef])}
          onClick={(e) => e.stopPropagation()}
          className={classNames("absolute shadow-3 z-50 rounded min-w-25 ", className)}
          {...props}
          style={{
            visibility: !isPositionCalculated ? "hidden" : "visible",
            left: position.left,
            top: position.top,
            ...props.style,
          }}
        >
          <ul
            className={classNames("bg-white-0 dark:bg-dark-theme-200 rounded flex flex-col py-2 custom-scroll", {
              "overflow-y-auto": scrollable.vertical,
              "overflow-y-hidden": !scrollable.vertical,
              "overflow-x-auto": scrollable.horizontal,
              "overflow-x-hidden": !scrollable.horizontal,
            })}
            style={menuSizeStyle}
          >
            {children}
          </ul>
        </div>
      </div>
    )
  );
});
