import { PreferenceName, UpdatePreferencesInput } from "@libs/graphql/generated-query";
import { DEFAULT_PREFERENCE, LineSpacingCategory, PreferenceValue } from "@models/preferences";

const preferenceParser = <T extends PreferenceName>(name: T, value: PreferenceValue[T]) => {
  const convertOnOffToBoolean = (value: unknown) => {
    return value === "on";
  };

  const convertLineSpacing = (value: LineSpacingCategory | unknown) => {
    if (value === "140") return "Tighter";
    if (value === "200") return "Spacier";
    return "Regular";
  };

  switch (name) {
    case PreferenceName.EditorMargin:
      return { name: "margin", value: `${value}px` };
    case PreferenceName.Font:
      return { name: "font", value };
    case PreferenceName.FontSize:
      return { name: "font_size", value: `${value}px` };
    case PreferenceName.LineSpacing:
      return { name: "line_spacing", value: convertLineSpacing(value) };
    case PreferenceName.TextIndent:
      return { name: "text_indent", value: convertOnOffToBoolean(value) };
    case PreferenceName.TypewriterMode:
      return { name: "typewriter_mode", value: convertOnOffToBoolean(value) };
    case PreferenceName.SmartQuote:
      return { name: "smart_quote", value: convertOnOffToBoolean(value) };
    case PreferenceName.TextReplaceMode:
      return { name: "text_replacement", value: convertOnOffToBoolean(value) };
    case PreferenceName.Theme:
      return { name: "dark_mode", value: value === "dark" };
  }
};

const preferenceToPayload = (preferenceResponses: UpdatePreferencesInput | UpdatePreferencesInput[]) => {
  if (Array.isArray(preferenceResponses)) {
    return preferenceResponses.reduce(
      (acc, preference) => {
        const parsedPreference = preferenceParser(preference.name, preference.value);
        if (!parsedPreference) return acc;
        acc[parsedPreference.name] = preference.value;
        return acc;
      },
      {} as { [key: string]: string | boolean }
    );
  }
  const parsedPreference = preferenceParser(preferenceResponses.name, preferenceResponses.value);
  if (!parsedPreference) return {};
  return { [parsedPreference.name]: parsedPreference.value };
};

const defaultPreferenceToPayload = () => {
  const payload = Object.entries(DEFAULT_PREFERENCE).reduce(
    (acc, [preferenceName, value]) => {
      const parsedPreference = preferenceParser(preferenceName as PreferenceName, value.value);
      if (!parsedPreference) return acc;
      acc[parsedPreference.name] = parsedPreference.value as string | boolean;
      return acc;
    },
    {} as { [key: string]: string | boolean }
  );

  return payload;
};

export default { preferenceToPayload, defaultPreferenceToPayload };
