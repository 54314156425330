import { forwardRef, Ref } from "react";

import "./Checkbox.css";
import classNames from "classnames";

import { CheckboxProps } from "./Checkbox.types";
import CheckboxIcon from "./CheckBoxIcon";

export { CheckboxIcon };
export const Checkbox = forwardRef(function CheckboxImpl(
  { className, size = "md", disabled = false, title, description, titleClassNames, ...restProps }: CheckboxProps,
  forwardedRef: Ref<HTMLInputElement>
) {
  return (
    <div className={classNames("flex flex-col items-start", { [`checkbox-${size}`]: true }, className)}>
      <label className="checkbox-container py-[2px] inline-flex items-center gap-3 shrink-0">
        <input ref={forwardedRef} type="checkbox" disabled={disabled} className="peer sr-only" {...restProps} />

        <div
          className={classNames(
            "checkbox-icon peer-checked:border-success-600 peer-checked:text-success-600",

            {
              ["checkbox-icon-disabled"]: disabled,
            }
          )}
        >
          <CheckboxIcon size={size}></CheckboxIcon>
        </div>
        <p className={classNames("checkbox-title", titleClassNames)}>{title}</p>
      </label>
      <div className=" inline-flex items-center gap-3">
        <div className="checkbox-empty flex invisible" />
        {description && <p className="text-body-sm text-gray-700">{description}</p>}
      </div>
    </div>
  );
});
