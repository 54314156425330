import { useStore } from "zustand";
import { combine } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createStore } from "zustand/vanilla";

import { DesktopStoreState } from "./types";

const initialDesktopState: DesktopStoreState = {
  localBackup: {
    enabled: false,
    path: "",
  },
};

const desktopStore = createStore(
  immer(
    combine(initialDesktopState, (set) => ({
      actions: {
        enableLocalBackup: () =>
          set((state) => {
            state.localBackup.enabled = true;
          }),
        disableLocalBackup: () =>
          set((state) => {
            state.localBackup.enabled = false;
          }),
        toggleLocalBackup: () =>
          set((state) => {
            state.localBackup.enabled = !state.localBackup.enabled;
          }),
        setLocalBackupPath: (path: string) =>
          set((state) => {
            state.localBackup.path = path;
          }),
      },
    }))
  )
);

const useDesktopStore = () => useStore(desktopStore);

export const getDesktopLocalBackupEnabled = () => desktopStore.getState().localBackup.enabled;

export const useDesktopLocalBackupEnabled = () => useDesktopStore().localBackup.enabled;
export const useDesktopLocalBackupPath = () => useDesktopStore().localBackup.path;

export const useDesktopActions = () => useDesktopStore().actions;
