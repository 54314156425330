export const WINDOW_MESSAGES = {
  GET_LOCAL_BACKUP_PATH: "get-local-backup-path",
  GET_LOCAL_BACKUP_ENABLED: "get-local-backup-enabled",
  ENABLE_LOCAL_BACKUP: "enable-local-backup",
  DISABLE_LOCAL_BACKUP: "disable-local-backup",
  CHANGE_LOCAL_BACKUP_PATH: "change-local-backup-path",
  SAVE_LOCAL_BACKUP: "save-local-backup",
  SHOW_KEYBOARD_SHORTCUTS: "show-keyboard-shortcuts",

  GET_LOCAL_BACKUP_PATH_REPLY: "get-local-backup-path-reply",
  GET_LOCAL_BACKUP_ENABLED_REPLY: "get-local-backup-enabled-reply",
  ENABLE_LOCAL_BACKUP_REPLY: "enable-local-backup-reply",
  DISABLE_LOCAL_BACKUP_REPLY: "disable-local-backup-reply",
  CHANGE_LOCAL_BACKUP_PATH_REPLY: "change-local-backup-path-reply",
  SAVE_LOCAL_BACKUP_SUCCESS_REPLY: "save-local-backup-success-reply",
  SAVE_LOCAL_BACKUP_FAIL_REPLY: "save-local-backup-fail-reply",
} as const;
