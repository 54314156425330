import { useHistory } from "react-router-dom";

import { ReactComponent as LeftIcon } from "@assets/icons/left.svg";
import IconContainer from "@components/IconContainer";

type Props = {
  children: React.ReactNode;
  goBackRoute?: string;
};

function AuthLayout({ children, goBackRoute }: Props) {
  const history = useHistory();

  const onClickGoBack = () => {
    if (!goBackRoute) return;

    history.push(goBackRoute);
  };

  return (
    <div className="flex items-center w-full h-screen overflow-y-auto">
      <div className="w-90 h-175 flex flex-col mx-auto my-auto">
        <div className="h-9 w-full mx-3 mt-4">
          {goBackRoute && (
            <IconContainer className="w-9 h-9" icon={<LeftIcon width={9} height={16} />} onClick={onClickGoBack} />
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

export default AuthLayout;
