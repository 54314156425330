import { captureException } from "@sentry/react";

import MixPanelClient from "@libs/mixpanel/mixpanelClient";
import OAuthClient from "@libs/OAuth/OAuthClient";

import { useSignInWithCode } from "./useSignInWithCode";

const APPLE_SIGN_IN_ERROR = ["user_trigger_new_signin_flow", "popup_closed_by_user"];

export const useHandleAppleSign = ({ isSignUp = false } = {}) => {
  const { signInWithCode } = useSignInWithCode();

  const handleClickAppleSign = async () => {
    if (isSignUp) {
      MixPanelClient.track("sign_up_start");
    }

    try {
      const result = await new OAuthClient("apple").authenticate();
      if (result.authorization) {
        signInWithCode(result.authorization.code, "apple");
      }
    } catch (e: any) {
      if (!APPLE_SIGN_IN_ERROR.includes(e?.error)) {
        captureException(e);
      }
    }
  };

  return { handleClickAppleSign };
};
